import React, { useEffect } from 'react';
import { Heading } from '@web-for-marketing/react-ui';
import { v2Colors } from '@web-for-marketing/react-ui';
import { Grid } from '@mui/material';
import { getSessionStorageItem } from '@services/sessionStorageService';
import { DataLayerFormValue } from '@models/dataLayer';
import { isEditingAtom } from '@atoms/admin';
import { useAtom } from 'jotai';

const classes = {
    adminContainer: {
        backgroundColor: v2Colors.core.cloud,
    },
    adminHeading: {
        margin: '8rem auto',
    },
};

declare const window: Window & { dataLayer: DataLayerFormValue[] };

export function GTMDataLayerForwarder(): JSX.Element | null {
    const [isEditing] = useAtom(isEditingAtom);

    useEffect(() => {
        const gtmDataLayer = window.dataLayer;
        const sessionData = getSessionStorageItem('dataLayerFormValues');
        const dataLayerFormValues = sessionData ? JSON.parse(sessionData) : null;

        if (gtmDataLayer && dataLayerFormValues && Array.isArray(dataLayerFormValues)) {
            dataLayerFormValues.forEach((value: DataLayerFormValue) => {
                gtmDataLayer.push(value);
            });
            sessionStorage.removeItem('dataLayerFormValues');
        }
    }, []);

    return isEditing ? (
        <Grid container alignContent='center' css={classes.adminContainer}>
            <Heading variant='h2' color='primary' css={classes.adminHeading}>
                GTM Data Layer Forwarder Section
            </Heading>
        </Grid>
    ) : null;
}
