import React from 'react';
import { Grid } from '@mui/material';
import { getAnimationStyle } from './helpers/AnimationStyleHelper';
import { LargeStackItem } from './helpers/LargeStackItem';
import { SmallStackItem } from './helpers/SmallStackItem';
import { featureIconsStyles as classes } from './styleObjects/FeatureIcons';
import { ParseAbbreviations } from '../ParseAbbreviations';
import { isPreviewingAnimationAtom } from '@atoms/animation';
import { useAtom } from 'jotai';
import { Heading } from '@web-for-marketing/react-ui';
import { RichTextContent } from '@components/RichTextContent';
import {
    BoxFeature,
    FeaturesSectionContent,
    LargeStackFeature,
    SmallStackFeature,
} from '@models/section/featuresSection';
import { SectionAnimation } from '@models/section';
import { BoxFeatureItem } from './helpers/BoxFeatureItem';
import { SectionContainer } from './SectionContainer';

type FeaturesProps = FeaturesSectionContent & {
    lazyLoad: boolean;
    animations: SectionAnimation;
    headerSection?: boolean;
};

export function Features({
    title,
    body,
    features,
    animations,
    align,
    alignFeatures,
    type,
    lazyLoad,
    headerSection,
}: FeaturesProps): JSX.Element {
    const [isPreviewingAnimation] = useAtom(isPreviewingAnimationAtom);
    const shouldIncreaseHeadingLevel = headerSection || !title;

    return (
        <SectionContainer className={`${animations.animationsEnabled && 'animateContainers'}`}>
            <Grid
                container
                className={`${
                    animations.sectionAnimation
                        ? getAnimationStyle('sectionAnimation', true, animations, isPreviewingAnimation)
                        : ''
                }`}
                justifyContent='center'
            >
                {title ? (
                    <Heading variant='h2' component={headerSection ? 'h1' : 'h2'} css={classes.titles}>
                        {title}
                    </Heading>
                ) : null}
                {body ? (
                    <RichTextContent
                        content={ParseAbbreviations({ content: body, stringFormat: true })}
                        css={classes.bodies}
                        color='secondary'
                    />
                ) : null}
                <Grid container spacing={{ xs: '1.2rem', md: '4rem' }} justifyContent={align}>
                    {features.map((feature, index) => {
                        switch (type) {
                            case 'smallStack':
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        md={4}
                                        key={index}
                                        className={`${getAnimationStyle(
                                            `featuresAnimation${index}`,
                                            index === 0,
                                            animations,
                                            isPreviewingAnimation
                                        )}`}
                                    >
                                        <SmallStackItem
                                            feature={feature as SmallStackFeature}
                                            lazyLoad={lazyLoad}
                                            alignFeatures={alignFeatures}
                                            shouldIncreaseHeadingLevel={shouldIncreaseHeadingLevel}
                                        />
                                    </Grid>
                                );
                            case 'largeStack':
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        className={`${getAnimationStyle(
                                            `featuresAnimation${index}`,
                                            index === 0,
                                            animations,
                                            isPreviewingAnimation
                                        )}`}
                                    >
                                        <LargeStackItem
                                            feature={feature as LargeStackFeature}
                                            animations={animations}
                                            lazyLoad={lazyLoad}
                                            alignFeatures={alignFeatures}
                                            shouldIncreaseHeadingLevel={shouldIncreaseHeadingLevel}
                                        />
                                    </Grid>
                                );
                            case 'boxes':
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        md={4}
                                        key={index}
                                        className={`${getAnimationStyle(
                                            `featuresAnimation${index}`,
                                            index === 0,
                                            animations,
                                            isPreviewingAnimation
                                        )}`}
                                    >
                                        <BoxFeatureItem
                                            feature={feature as BoxFeature}
                                            lazyLoad={lazyLoad}
                                            alignFeatures={alignFeatures}
                                            shouldIncreaseHeadingLevel={shouldIncreaseHeadingLevel}
                                        />
                                    </Grid>
                                );
                            default:
                                break;
                        }
                    })}
                </Grid>
            </Grid>
        </SectionContainer>
    );
}

Features.defaultProps = {
    features: [
        { title: '', body: '', image: { imageAlt: '', imagePath: '' } },
        { title: '', body: '', image: { imageAlt: '', imagePath: '' } },
        { title: '', body: '', image: { imageAlt: '', imagePath: '' } },
    ],
    animations: {
        animationsEnabled: false,
    },
    align: 'center',
    alignFeatures: 'left',
    type: 'smallStack',
};
