import React from 'react';

export function OutlinedCheckmarkIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden
            {...props}
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12 3.36C7.22821 3.36 3.35995 7.22826 3.35995 12C3.35995 16.7717 7.22821 20.64 12 20.64C16.7717 20.64 20.6399 16.7717 20.6399 12C20.6399 7.22826 16.7717 3.36 12 3.36ZM1.19995 12C1.19995 6.03532 6.03528 1.2 12 1.2C17.9646 1.2 22.7999 6.03532 22.7999 12C22.7999 17.9647 17.9646 22.8 12 22.8C6.03528 22.8 1.19995 17.9647 1.19995 12ZM15.9238 8.46412C16.3855 8.84183 16.4535 9.52225 16.0758 9.98389L11.2158 15.9239C11.0226 16.1601 10.7385 16.3035 10.4338 16.3187C10.129 16.3339 9.83204 16.2194 9.61627 16.0037L7.45628 13.8437C7.03451 13.4219 7.03451 12.7381 7.45628 12.3163C7.87804 11.8946 8.56186 11.8946 8.98363 12.3163L10.2998 13.6325L14.4041 8.6161C14.7818 8.15446 15.4622 8.08642 15.9238 8.46412Z'
                fill='currentColor'
            />
        </svg>
    );
}
