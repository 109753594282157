import React from 'react';
import { Video } from '@models/video';
import { videoLibraryStyles as classes } from './videoLibraryStyles';
import { getLanguageRouteAndPrefixByIdAndRouteKey } from '@helpers/languages';
import { VideoPlayIcon } from '@icon/VideoPlayIcon';
import { getVideoThumbnailImage } from '@helpers/videoDriveKeyHelper';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { Heading, Text } from '@web-for-marketing/react-ui';
import { languageRouteTypes } from '@helpers/languageRoutes';
import { RichTextContent } from '@components/RichTextContent';
import { getTranslation } from '@helpers/languageTranslations';
import { useTranslation } from '@stateManagement/TranslationContext';

export interface VideoLibraryCardProps {
    video: Video;
    lazyLoad: boolean;
    connect2024?: boolean;
}

export function VideoLibraryCard({ connect2024, video, lazyLoad }: VideoLibraryCardProps): JSX.Element {
    const { prefixStringWithBaseUrl } = useStaticValues();
    const { selectedLanguageTranslation } = useTranslation();
    const videoTranslation = getTranslation(selectedLanguageTranslation, 'videoTile');

    const videoImage = video.imagePath
        ? prefixStringWithBaseUrl(video.imagePath)
        : getVideoThumbnailImage(video.videoKey, video.videoType);

    return (
        <a
            data-testid='videoLibraryCard'
            css={classes.videoCard(connect2024)}
            href={`${getLanguageRouteAndPrefixByIdAndRouteKey(video.language, languageRouteTypes.VIDEO)}${video.slug}`}
        >
            <div css={classes.videoCardContent}>
                <div css={classes.imageContainer}>
                    <img
                        data-testid='videoLibraryCardImg'
                        css={classes.cardImage}
                        aria-hidden
                        src={!lazyLoad ? videoImage : ''}
                        data-src={lazyLoad ? videoImage : ''}
                        alt={`${video.title} video thumbnail`}
                    />
                    <VideoPlayIcon fillOpacity={0.85} css={classes.playIcon} />
                </div>
                <div css={classes.cardText}>
                    {video.tags && video.tags.length ? (
                        <div css={classes.cardChip(connect2024)}>{video.tags[0].name}</div>
                    ) : null}
                    <Heading
                        variant='h4'
                        component='h2'
                        css={classes.cardTitle}
                        color={connect2024 ? 'inversePrimary' : undefined}
                    >
                        {video.title}
                    </Heading>
                    <Text
                        className='videoCardCTA'
                        css={classes.videoCardCTA}
                        underline
                        variant='body2'
                        color={connect2024 ? 'inversePrimary' : 'activePrimary'}
                        weight='bold'
                    >
                        {videoTranslation.watchVideo ?? 'Watch Video'}
                    </Text>
                    <div className='videoCardDescription' css={classes.videoCardDescription}>
                        <RichTextContent
                            content={video.description}
                            variant='body2'
                            color={connect2024 ? 'inversePrimary' : 'primary'}
                            css={classes.cardDescription}
                        />
                        <Text
                            underline
                            variant='body2'
                            color={connect2024 ? 'inversePrimary' : 'activePrimary'}
                            weight='bold'
                        >
                            {videoTranslation.watchVideo ?? 'Watch Video'}
                        </Text>
                    </div>
                </div>
            </div>
        </a>
    );
}
