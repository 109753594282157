import React from 'react';
import { ModularSectionData } from '@models/section/modularSection';
import { LazyLoadContainer } from '@web-for-marketing/react-ui';
import { GridContainer } from '@components/GeotabGridComponents';
import { Grid } from '@mui/material';
import { RenderColumnTypes } from './helpers/RenderColumnTypes';
import { SectionColumn, FlexAlignment } from '@models/column';
import { getTotalColumnSpanCount, getColumnCount } from './helpers/modularSectionHelper';
import { breakpoints } from '@web-for-marketing/react-ui';
import { splitPaddingValues } from './helpers/spacingStyleHelpers';
import { getAnimationStyle } from './helpers/AnimationStyleHelper';
import { useAtom } from 'jotai';
import { SectionAnimation, ContentSpacing } from '@models/section';
import { isMobileAtom } from '@atoms/appSettings';
import { isPreviewingAnimationAtom } from '@atoms/animation';

interface ModularSectionProps {
    sectionData: ModularSectionData;
    lazyLoad: boolean;
    headerSection?: boolean;
    animations?: SectionAnimation;
}

const flexAlignToTextAlign = {
    'flex-start': 'left',
    center: 'center',
    'flex-end': 'right',
} as const;

const classes = {
    module: (horizontalAlignment: FlexAlignment) => ({
        textAlign: flexAlignToTextAlign[horizontalAlignment] || 'left',
        '&:not(:last-of-type)': {
            [`@media (max-width: ${breakpoints.md}px)`]: {
                marginBottom: '4rem',
            },
        },
    }),
    mainContainer: (padding: ContentSpacing, maxWidth?: number) => {
        const { paddingTop, paddingBottom } = splitPaddingValues(padding);

        return {
            maxWidth: maxWidth,
            [`@media (min-width: ${breakpoints.md}px)`]: {
                paddingTop: `${paddingTop}rem`,
                paddingBottom: `${paddingBottom}rem`,
            },
        };
    },
};

export function ModularSection({ sectionData, lazyLoad, headerSection, animations }: ModularSectionProps): JSX.Element {
    const totalColumnSpanCount = getTotalColumnSpanCount(sectionData.modules);
    const spacing = sectionData.spacing || '8rem 0';
    const maxWidth = sectionData.condenseContent ? 1400 : undefined;
    const [isPreviewingAnimation] = useAtom(isPreviewingAnimationAtom);
    const [isMobile] = useAtom(isMobileAtom);
    const modules = sectionData.mobileSwap && isMobile ? sectionData.modules.reverse() : sectionData.modules;

    return (
        <LazyLoadContainer component={Grid} lazyload={lazyLoad} className={animations ? 'animateContainers' : ''}>
            <GridContainer
                container
                css={classes.mainContainer(spacing, maxWidth)}
                className={`${getAnimationStyle('sectionAnimation', true, animations, isPreviewingAnimation)}`}
            >
                {modules.map((module, index) => {
                    // the column span of the module in a 12 column layout
                    const twelveColumnSpan = (12 * module.columnSpan) / totalColumnSpanCount;
                    // the twelveColumnSpan value can be a decimal, so we round it up for when a whole value is needed
                    const roundedTwelveColumnSpan = Math.ceil(twelveColumnSpan);

                    return (
                        <Grid
                            key={index}
                            data-testid={`modular-column-${index}`}
                            container
                            item
                            xs={totalColumnSpanCount}
                            md={module.columnSpan}
                            columns={totalColumnSpanCount}
                            direction='column'
                            justifyContent={module.verticalAlignment}
                            alignItems={module.horizontalAlignment}
                            className={`${getAnimationStyle(
                                undefined,
                                true,
                                animations,
                                isPreviewingAnimation,
                                module.animation
                            )}`}
                            css={classes.module(module.horizontalAlignment)}
                        >
                            <RenderColumnTypes
                                column={
                                    {
                                        type: module.type,
                                        content: module.content,
                                    } as SectionColumn
                                }
                                lazyLoad={lazyLoad}
                                headerSection={headerSection}
                                columnCount={getColumnCount(roundedTwelveColumnSpan)}
                                horizontalAlignment={module.horizontalAlignment}
                            />
                        </Grid>
                    );
                })}
            </GridContainer>
        </LazyLoadContainer>
    );
}
