import React from 'react';
import { Picture } from '../../Picture';
import { Grid, Link } from '@mui/material';
import { testimonialStyles as classes } from './styleObjects/Testimonial';
import { RatingSelect } from '@components/BlogSection/BlogComponents/Shared/RatingSelect';
import { getLinkTargetValue } from './SectionComponentHelper';
import { QuoteIcon } from '@components/IconComponents/QuoteIcon';
import { NewTabIcon, Heading, Text } from '@web-for-marketing/react-ui';
import type { ColumnCount, TestimonialColumnContent } from '@models/column';

interface TestimonialProps {
    content: TestimonialColumnContent;
    lazyLoad?: boolean;
    columnCount?: ColumnCount;
}

export function Testimonial({ lazyLoad, content, columnCount }: TestimonialProps): JSX.Element | null {
    const isMultiColumn = columnCount && columnCount > 1;

    if (content.buttonEnabled === undefined) {
        content.buttonEnabled = true;
    }

    if (content.ratingEnabled) {
        content.testimonialStyle = 'Rating';
    }

    const buttonTarget = getLinkTargetValue(content.button?.buttonTarget) || '_self';

    return (
        <Grid container justifyContent='center' data-testid='testimonialContainer'>
            <Grid
                item
                xs={12}
                md={isMultiColumn ? undefined : 10}
                container
                alignItems='center'
                direction={content.direction === 'Right' ? 'row-reverse' : 'row'}
            >
                <div css={classes.profileContainer(content.direction, columnCount)}>
                    <Picture
                        imagePath={content.image && content.image.imagePath}
                        alt={content.image?.imageAlt || ''}
                        lazyLoad={lazyLoad}
                        key={content.image && content.image.imagePath}
                        css={classes.portrait}
                        fullWidth={false}
                    />
                    <div css={classes.profileInfo(columnCount)}>
                        {content.name ? (
                            <Heading variant='h4' component='p'>
                                {content.name}
                            </Heading>
                        ) : null}
                        {content.role ? (
                            <Text variant='body1' color='secondary'>
                                {content.role}
                            </Text>
                        ) : null}
                    </div>
                </div>
                <div
                    css={[
                        classes.content,
                        content.showTestimonialBackground
                            ? classes.testimonialBackground(content.testimonialBackgroundColor)
                            : undefined,
                    ]}
                >
                    {content.testimonialStyle === 'Rating' ? (
                        <Grid
                            container
                            spacing={2}
                            css={[{ margin: '-8px', '& > .MuiGrid-item': { padding: '8px' } }, classes.ratingContainer]}
                            alignItems='center'
                        >
                            <Grid item>
                                <RatingSelect rating={content.rating} />
                            </Grid>
                            <Grid item>
                                <Text variant='body1' color='tertiary'>{`${content.rating}/5 stars`}</Text>
                            </Grid>
                        </Grid>
                    ) : null}
                    <Grid
                        container
                        spacing={2}
                        css={{ margin: '-8px', '& > .MuiGrid-item': { padding: '8px' } }}
                        wrap='nowrap'
                    >
                        {content.testimonialStyle === 'Quote' ? (
                            <Grid item>
                                <QuoteIcon css={classes.quoteIcon} />
                            </Grid>
                        ) : null}
                        <Grid item>
                            {content.body ? (
                                <Text variant='body1' color='secondary'>
                                    {content.body}
                                </Text>
                            ) : null}
                            {content.buttonEnabled && content.button ? (
                                <div css={classes.linkContainer}>
                                    <Text
                                        component={Link}
                                        variant='body1'
                                        css={classes.testimonialLink}
                                        href={content.button.buttonLinkPath}
                                        target={buttonTarget}
                                    >
                                        <span css={classes.linkText}>{content.button.buttonText}</span>{' '}
                                        {buttonTarget === '_blank' ? <NewTabIcon css={classes.newTabIcon} /> : null}
                                    </Text>
                                </div>
                            ) : null}
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
}
