export const connectColors = {
    electricBlue: '#0033FF',
    neonYellow: '#FFF100',
    neonGreen: '#00FF00',
    black: '#000000',
    white: '#FFFFFF',
    grey: '#7f8084',
};

export const navLinks = [
    { text: 'Speakers', href: '#connect-speakers' },
    { text: 'Why attend', href: '#connect-why-attend' },
    { text: 'Sponsors', href: '#connect-sponsors' },
    { text: 'Exhibitors', href: '#connect-exhibitors' },
    { text: 'Registration fees', href: '#connect-fees' },
    { text: 'Video Hub', href: 'https://www.geotab.com/connect-video-hub', openInNewTab: true },
];

export const registerLink = 'https://site.pheedloop.com/event/Connect2024/register#start';

export const scheduleLink = 'https://site.pheedloop.com/event/Connect2024/schedule';

export const liveStreamLink = 'https://pheedloop.com/Connect2024/virtual/?page=lobby';

export const keepInTouchLink = 'https://www.geotab.com/connect24-event-notice/';
