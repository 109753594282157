import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

interface TimeLeft {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

const dateAndTimesFormatOrder = ['year', 'month', 'day', 'hour', 'minute'];
const parsedDateTimeFormat = 'YYYY/MM/DD/HH/mm';

export function getPercentage(value: number, total: number): number {
    return Math.round((value / total) * 100);
}

export function calculateTimeLeft(utcDateTime: string): TimeLeft {
    let timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    };

    const dateAndTimes = dayjs
        .utc(utcDateTime)
        .local()
        .format(parsedDateTimeFormat)
        .split('/')
        .reduce<Record<string, number>>((dateTimeObj, value, i) => {
            dateTimeObj[dateAndTimesFormatOrder[i]] = +value;
            return dateTimeObj;
        }, {});

    const currentDate = +new Date();
    const countdownDate = +new Date(
        dateAndTimes.year,
        dateAndTimes.month - 1,
        dateAndTimes.day,
        dateAndTimes.hour,
        dateAndTimes.minute
    );
    const difference = countdownDate - currentDate;

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    }

    return timeLeft;
}
