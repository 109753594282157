import React, { useState } from 'react';
import { SelectIcon } from '@web-for-marketing/react-ui';
import { videoLibraryStyles as classes } from './videoLibraryStyles';
import { ClassNames } from '@emotion/react';
import { Menu, MenuItem } from '@mui/material';
import { TagFilter, VideoTag } from '@models/section/videoLibrarySection';

export interface TagFilterBarMenuProps {
    selectedTag: TagFilter;
    menuItems: VideoTag[];
    onSelect: (tagId: number) => void;
    connect2024?: boolean;
}

export function TagFilterBarMenu({
    selectedTag,
    menuItems,
    onSelect,
    connect2024,
}: TagFilterBarMenuProps): JSX.Element {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    function handleClick(event: React.MouseEvent<HTMLButtonElement>): void {
        setAnchorEl(event.currentTarget);
    }

    function handleClose(): void {
        setAnchorEl(null);
    }

    return (
        <>
            <button
                id='video-library-more-tags-button'
                aria-controls={open ? 'video-library-more-tags-menu' : undefined}
                aria-haspopup={true}
                aria-expanded={open ? true : undefined}
                onClick={handleClick}
                css={classes.filterChip(
                    selectedTag !== 'All' && menuItems.map((item) => item.id).includes(selectedTag),
                    connect2024
                )}
            >
                More
                <SelectIcon css={{ marginLeft: '1rem' }} />
            </button>
            <ClassNames>
                {({ css }) => (
                    <Menu
                        id='video-library-more-tags-menu'
                        elevation={0}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'video-library-more-tags-button',
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        classes={{
                            paper: css(classes.menu),
                        }}
                    >
                        {menuItems.map((item) => (
                            <MenuItem
                                selected={selectedTag === item.id}
                                css={classes.menuItem}
                                key={item.id}
                                onClick={() => onSelect(item.id)}
                            >
                                {item.name}
                            </MenuItem>
                        ))}
                    </Menu>
                )}
            </ClassNames>
        </>
    );
}
