import React, { useEffect, useRef } from 'react';
import { VideoBackground } from '@models/layeredBackground';
import { useStaticValues } from '@stateManagement/StaticValuesContext';

interface LayeredVideoBackgroundProps {
    background: VideoBackground;
    lazyLoad: boolean;
}

export function LayeredVideoBackground({ background, lazyLoad }: LayeredVideoBackgroundProps): JSX.Element {
    const { prefixStringWithBaseUrl } = useStaticValues();
    const mediaSrc = prefixStringWithBaseUrl(background.config.mediaUrl);
    const videoRef = useRef<HTMLVideoElement>(null);

    // React sets the muted property and does not guarantee that the muted attribute will appear on the video element.
    // This could cause issues in some browsers that look for the muted attribute as mentioned in https://github.com/facebook/react/issues/10389.
    // This is to enure that the muted attribute will appear on the video element.
    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.setAttribute('muted', '');
        }
    }, []);

    return (
        <>
            <video
                data-testid='layered-video-background'
                ref={videoRef}
                muted
                aria-hidden
                autoPlay
                playsInline
                loop
                src={lazyLoad ? '' : mediaSrc}
                data-src={lazyLoad ? mediaSrc : ''}
                css={{
                    position: 'absolute',
                    inset: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                }}
            />
        </>
    );
}
