import React from 'react';
import { useAtom } from 'jotai';
import { isMobileAtom } from '@atoms/appSettings';
import { iframeStyles as classes } from './styleObjects/IframeStyles';
import { getAnimationStyle } from './helpers/AnimationStyleHelper';
import { Heading, LazyLoadContainer } from '@web-for-marketing/react-ui';
import { isPreviewingAnimationAtom } from '@atoms/animation';
import { IframeData } from '@models/section/iFrameSection';
import { SectionAnimation } from '@models/section';
import { Grid } from '@mui/material';

export const defaultHeight = '200px';
export const defaultWidth = '80%';

interface IframeProps {
    lazyLoad: boolean;
    iframeComponent: IframeData;
    animations?: SectionAnimation;
}

export function Iframe({ iframeComponent, animations, lazyLoad }: IframeProps): JSX.Element {
    const [isMobile] = useAtom(isMobileAtom);
    const [isPreviewingAnimation] = useAtom(isPreviewingAnimationAtom);
    const height = isMobile ? iframeComponent.mobileHeight || defaultHeight : iframeComponent.height || defaultHeight;
    const width = isMobile ? iframeComponent.mobileWidth || defaultWidth : iframeComponent.width || defaultWidth;

    return (
        <LazyLoadContainer
            component={Grid}
            lazyload={lazyLoad}
            css={classes.container}
            className={`${
                animations &&
                animations.sectionAnimation &&
                getAnimationStyle('sectionAnimation', true, animations, isPreviewingAnimation)
            }`}
        >
            {iframeComponent.url ? (
                <iframe
                    title={iframeComponent.title || 'Interactive graphic'}
                    css={[
                        classes.iframe,
                        !iframeComponent.iframeBorder ? classes.removeBorder : undefined,
                        { height: height, width: width },
                    ]}
                    data-src={lazyLoad ? iframeComponent.url : undefined}
                    src={lazyLoad ? '' : iframeComponent.url}
                    data-testid='iframe'
                />
            ) : (
                <Heading variant='h2' css={[{ height: height, width: width }, classes.invalidHeading]}>
                    Url is not valid.
                </Heading>
            )}
        </LazyLoadContainer>
    );
}
