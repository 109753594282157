import { httpGet } from './apiService';
import { Video } from '@models/video';
import { PagedResult } from '@models/pagination';

const videoApiUrl = '/api/video';

export function getVideosByTagId(tagIds: number[], page = 1, maxRecordsPerPage = 9): Promise<PagedResult<Video>> {
    const ids = tagIds.map((tagId) => `tagIds=${tagId}`).join('&');
    return httpGet(`${videoApiUrl}/paged/tag/?${ids}&page=${page}&maxRecordsPerPage=${maxRecordsPerPage}`).then(
        (result) => result.json()
    );
}
