import React from 'react';
import { VideoLibraryData } from '@models/section/videoLibrarySection';
import { GridContainer } from '@components/GeotabGridComponents/GridContainer';
import { VideoLibraryContent } from './VideoLibraryContent';
import { useAtom } from 'jotai';
import { navHeightAtom } from '@atoms/appSettings';

interface VideoLibraryProps {
    sectionData: VideoLibraryData;
    lazyLoad: boolean;
    headerSection?: boolean;
}

export function VideoLibrary({ sectionData, lazyLoad, headerSection }: VideoLibraryProps): JSX.Element {
    const [navHeight] = useAtom(navHeightAtom);

    return (
        <GridContainer container alignItems='center' flexDirection='column'>
            <VideoLibraryContent
                lazyLoad={lazyLoad}
                title={sectionData.title}
                description={sectionData.description}
                tags={sectionData.tags}
                displayTagCategories={sectionData.displayTagCategories}
                headerSection={headerSection}
                scrollTopSpacing={navHeight}
            />
        </GridContainer>
    );
}
