export const MOBILE_BACKGROUND_LAYER_KEY = 'mobileBackgroundLayers';

export const BACKGROUND_LAYER_KEY = 'backgroundLayers';

export type BackgroundLayerKey = typeof BACKGROUND_LAYER_KEY | typeof MOBILE_BACKGROUND_LAYER_KEY;

export type Background = ImageBackground | VideoBackground | ColorBackground | GradientBackground;

interface BackgroundLayer {
    enabled: boolean;
}

export interface ImageBackground extends BackgroundLayer {
    type: BackgroundType.Image;
    config: ImageBackgroundConfig;
}

export interface VideoBackground extends BackgroundLayer {
    type: BackgroundType.Video;
    config: VideoBackgroundConfig;
}

export interface ColorBackground extends BackgroundLayer {
    type: BackgroundType.Color;
    config: ColorBackgroundConfig;
}

export interface GradientBackground extends BackgroundLayer {
    type: BackgroundType.Gradient;
    config: GradientBackgroundConfig;
}

export enum BackgroundType {
    Color = 'color',
    Image = 'image',
    Video = 'video',
    Gradient = 'gradient',
}

export interface ImageBackgroundConfig {
    mediaUrl: string;
    horizontalDock: HorizontalDock;
    verticalDock: VerticalDock;
    parallax: boolean;
    horizontalOffset: number;
    verticalOffset: number;
    cover: boolean;
}

interface VideoBackgroundConfig {
    mediaUrl: string;
}

export interface ColorBackgroundConfig {
    color: string;
    transparency: number;
}

export interface GradientBackgroundConfig {
    type: GradientType;
    angle: number;
    colors: GradientColor[];
}

export interface GradientColor {
    color: string;
    position: number;
    transparency: number;
}

export enum HorizontalDock {
    Left = 'left',
    Center = 'center',
    Right = 'right',
}

export enum VerticalDock {
    Top = 'top',
    Center = 'center',
    Bottom = 'bottom',
}

export enum PredefinedBackgroundColor {
    Gray = 'gray',
    Black = 'black',
}

export enum PredefinedBackgroundGradient {
    Gradient1 = 'gradient1',
    Gradient2 = 'gradient2',
    Gradient3 = 'gradient3',
}

export enum GradientType {
    Linear = 'linear',
    Radial = 'radial',
}
