import React from 'react';
import { Grid, List, ListItem } from '@mui/material';
import { getAnimationStyle } from './AnimationStyleHelper';
import { featureIconsStyles as classes } from '../styleObjects/FeatureIcons';
import { ParseAbbreviations } from '../../ParseAbbreviations';
import { getLinkTargetValue } from './SectionComponentHelper';
import { CMSLink } from '@components/CMSLink';
import { isPreviewingAnimationAtom } from '@atoms/animation';
import { useAtom } from 'jotai';
import { Heading } from '@web-for-marketing/react-ui';
import { Picture } from '@components/Picture';
import { LargeStackFeature } from '@models/section/featuresSection';
import { TextAlignValues } from '@models/editorForm';
import { RichTextContent } from '@components/RichTextContent';
import { SectionAnimation } from '@models/section';

interface LargeStackItemProps {
    animations: SectionAnimation;
    lazyLoad: boolean;
    alignFeatures: TextAlignValues;
    feature: LargeStackFeature;
    shouldIncreaseHeadingLevel: boolean;
}

export function LargeStackItem({
    feature,
    animations,
    alignFeatures,
    lazyLoad,
    shouldIncreaseHeadingLevel,
}: LargeStackItemProps): JSX.Element {
    const [isPreviewingAnimation] = useAtom(isPreviewingAnimationAtom);
    const { image, title, body, list, button } = feature;

    return (
        <Grid container wrap='nowrap' direction={{ xs: 'column', md: 'row' }} data-testid='largeStackItem'>
            <Grid item>
                <Picture
                    fullWidth={false}
                    imagePath={image.imagePath}
                    mobilePath={image.mobilePath ? image.mobilePath : ''}
                    alt={image.imageAlt}
                    mobileAlt={image.mobileAlt}
                    lazyLoad={lazyLoad}
                    css={[classes.modelMedia, classes[`${alignFeatures}Image`]]}
                />
            </Grid>
            <Grid item>
                {title ? (
                    <Heading
                        variant='h3'
                        weight='regular'
                        component={shouldIncreaseHeadingLevel ? 'h2' : 'h3'}
                        color='primary'
                        css={[classes.modelTitle, classes[`${alignFeatures}Heading`]]}
                    >
                        <ParseAbbreviations content={title} />
                    </Heading>
                ) : null}
                {body ? (
                    <RichTextContent
                        content={ParseAbbreviations({ content: body, stringFormat: true })}
                        css={classes[`${alignFeatures}Body`]}
                    />
                ) : null}
                {list && list.length ? (
                    <Grid
                        item
                        css={classes.listContainer}
                        className={`${getAnimationStyle('listAnimation', false, animations, isPreviewingAnimation)}`}
                        xs={12}
                    >
                        <List dense css={classes.lists}>
                            {list.map((value, index: number) => {
                                return (
                                    <ListItem css={classes.listItems} key={index}>
                                        <ParseAbbreviations content={value} />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Grid>
                ) : null}
                {button && button.buttonText ? (
                    <CMSLink
                        type='button'
                        href={button.buttonLinkPath}
                        aria-label={button.ariaLabel}
                        target={getLinkTargetValue(button.buttonTarget)}
                        variant={button.buttonType || 'text'}
                    >
                        {button.buttonText}
                    </CMSLink>
                ) : null}
            </Grid>
        </Grid>
    );
}
