import { breakpoints, v2Colors } from '@web-for-marketing/react-ui';

export const subNavigationStyles = {
    subNavigationContainer: (isSticky?: boolean, topOffSet?: number, isMobile?: boolean) =>
        ({
            position: isSticky ? 'fixed' : undefined,
            zIndex: 300,
            backgroundColor: v2Colors.background.white,
            top: isSticky && topOffSet ? topOffSet : 0,
            left: 0,
            width: '100%',
            boxSizing: 'border-box',
            maxHeight: !isMobile ? '6.4rem' : 'unset',
            borderBottom: !isMobile ? '1px solid #D9E1E8' : 'none',
        }) as const,
    subNavigation: {
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        justifyContent: 'center',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        boxSizing: 'border-box',
        maxWidth: 1630,
        margin: '0 auto',
        minHeight: '6.4rem',
        maxHeight: '6.4rem',
        '@media (max-width: 1679.9px)': {
            paddingLeft: '3.6rem ',
            paddingRight: '3.6rem ',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            overflowX: 'hidden',
        },
    },
    smallLeftMarginIcon: {
        marginLeft: '0.5rem',
    },
    arrowContainer: {
        margin: '0 1.6rem',
    },
    buttonContainer: {
        margin: '0 .8rem',
        maxHeight: '4.2rem',
    },
    buttonItem: {
        width: '100%',
        padding: '.4rem 2.4rem !important',
        borderRadius: '.8rem',
        minHeight: '4.2rem',
    },
    buttonDivider: {
        marginRight: '2rem',
        borderLeft: '2px solid #C0CCD8',
        height: '2.6rem',
    },
    stickyHidden: {
        width: '100%',
        height: 1,
        marginBottom: -1,
        visibility: 'hidden',
    },
    linkItemContainer: {
        overflow: 'auto',
        scrollbarWidth: 'none',
    },
    linkItem: {
        color: v2Colors.text.activePrimary,
        padding: '.8rem 1.6rem',
        margin: '0 1.6rem',
        fontWeight: 500,
        fontSize: '1.8rem',
        textDecoration: 'none',
        borderRadius: '.8rem',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        '&:hover': {
            backgroundColor: `${v2Colors.core.cloud} !important`,
            color: v2Colors.text.activeSecondary,
            padding: '.8rem 1.6rem',
        },
        '&:focus-visible': {
            outline: 'none',
            textDecoration: 'underline',
            backgroundColor: `${v2Colors.core.cloud} !important`,
            color: v2Colors.text.activeSecondary,
            padding: '.8rem 1.6rem',
        },
    },
    arrowButton: {
        '&:hover': {
            backgroundColor: `${v2Colors.core.cloud} !important`,
            color: v2Colors.text.activeSecondary,
        },
    },
    flipArrow: {
        transform: 'scaleX(-1)',
    },
} as const;
