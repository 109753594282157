import { breakpoints, v2Colors } from '@web-for-marketing/react-ui';

export const subNavigationMobileStyles = {
    subNavigation: {
        overflow: 'hidden',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        boxSizing: 'border-box',
        maxWidth: 1630,
        margin: '0 auto',
        minHeight: '4rem',
        maxHeight: '4rem',
        borderBottom: '1px solid #D9E1E8',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            overflowX: 'hidden',
        },
        [`@media (min-width: ${breakpoints.sm}px)`]: {
            minHeight: '4rem',
            maxHeight: '4rem',
        },
    },
    dropDownButton: {
        display: 'flex',
        minHeight: '4rem',
        justifyContent: 'space-between',
        fontWeight: 500,
        fontSize: '1.4rem !important',
        textDecoration: 'none',
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        transform: 'translateX(0px)',
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            width: '100%',
        },
        '@media (max-width: 1679.9px)': {
            paddingLeft: '3.6rem !important',
            paddingRight: '3.6rem !important',
        },
        '&:focus': {
            color: v2Colors.text.activeSecondary,
        },
        '&:focus-visible': {
            outline: `1px solid ${v2Colors.core.innovation}`,
            outlineOffset: '-2px',
            borderRadius: '.6rem',
        },
    },
    dropDownMenu: {
        backgroundColor: v2Colors.background.white,
    },
    smallLeftMarginIcon: {
        marginLeft: '0.5rem',
    },
    arrowContainer: {
        margin: '0 1.6rem',
    },
    buttonContainer: {
        backgroundColor: v2Colors.background.light,
    },
    buttonItem: {
        width: '100%',
        padding: '0 !important',
        margin: 0,
        fontWeight: 500,
        fontSize: '1.4rem !important',
        textDecoration: 'none',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        minHeight: '4rem',
        display: 'flex',
        justifyContent: 'flex-start',
        transform: 'translateX(0px)',
        '@media (max-width: 1679.9px)': {
            padding: '1rem 3.6rem !important',
        },
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            width: '100%',
        },
        '&:hover': {
            color: v2Colors.text.activeSecondary,
        },
        '&:focus-visible': {
            outline: `1px solid ${v2Colors.core.innovation}`,
            outlineOffset: '-1px',
            borderRadius: '.6rem',
        },
    },
    linkItemContainer: {
        overflow: 'hidden',
        display: 'flex',
        borderBottom: `1px solid ${v2Colors.border.primary}`,
        minHeight: '4rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            overflowX: 'hidden',
        },
        padding: 0,
    },
    linkItem: {
        color: v2Colors.text.activePrimary,
        width: '100%',
        padding: 0,
        margin: 0,
        fontWeight: 500,
        fontSize: '1.4rem',
        textDecoration: 'none',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        '@media (max-width: 1679.9px)': {
            padding: '1rem 3.6rem ',
        },
        '&:hover': {
            color: v2Colors.text.activeSecondary,
            textDecoration: 'underline',
        },
        '&:focus': {
            color: v2Colors.text.activeSecondary,
        },
        '&:focus-visible': {
            outline: `1px solid ${v2Colors.core.innovation}`,
            outlineOffset: '-1px',
            borderRadius: '.6rem',
        },
    },
    rotate: {
        transform: 'rotate(180deg)',
    },
} as const;
