export const debounce = (
    func: (...args: unknown[]) => void,
    delay: number
): ((this: unknown, ...args: unknown[]) => void) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (this: unknown, ...args: unknown[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
};
