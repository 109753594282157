import React from 'react';

type ZoomIconProps = React.SVGProps<SVGSVGElement> & {
    zoomIn?: boolean;
    zoomOut?: boolean;
};

export function ZoomIcon({ zoomIn = false, zoomOut = false, ...other }: ZoomIconProps): JSX.Element {
    return (
        <svg
            aria-hidden
            data-name='ZoomIcon1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 22 22'
            style={{ color: '#FFFFFF' }}
            width='1em'
            height='1em'
            {...other}
        >
            <defs>
                <style>
                    {
                        '.zoomicon{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5 !important;}'
                    }
                </style>
            </defs>
            <path d='M15.15 15.14L21 20.99' className='zoomicon' />
            <path
                d='M9.28003 17.56C13.8529 17.56 17.5601 13.8529 17.5601 9.28C17.5601 4.70708 13.8529 1 9.28003 1C4.70711 1 1 4.70708 1 9.28C1 13.8529 4.70711 17.56 9.28003 17.56Z'
                className='zoomicon'
            />
            {zoomIn ? (
                <>
                    <path d='M9.28003 5.28V13.28' className='zoomicon' />
                    <path d='M13.28 9.28H5.28003' className='zoomicon' />
                </>
            ) : null}
            {zoomOut ? (
                <>
                    <path d='M13.28 9.28H5.28003' className='searchStroke' />
                </>
            ) : null}
        </svg>
    );
}
