import React from 'react';

export function QuoteIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            width='21'
            height='21'
            viewBox='0 0 21 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                d='M7.90076 4.5269C7.84349 4.33965 7.71193 4.18214 7.53503 4.08904C7.35813 3.99594 7.15038 3.97487 6.95747 4.03046C5.08596 4.56979 3.20608 6.25196 2.2403 8.3922C1.74795 9.48329 1.34733 10.9656 1.33199 12.3969C1.31685 13.8095 1.68051 15.3423 2.92569 16.2937C3.32115 16.5959 3.73443 16.8422 4.28256 16.9461C4.80743 17.0455 5.38313 17 6.08247 16.8705C7.68628 16.5734 9.06699 15.348 9.06699 13.6437V12.938C9.06699 11.2617 7.6671 9.90284 5.94024 9.90284H5.68652C5.47608 9.90284 5.27043 9.92308 5.07154 9.96164C5.11085 9.85622 5.15365 9.75249 5.19966 9.65054C5.84824 8.21323 7.06094 7.27212 7.87695 7.03696C8.27866 6.9212 8.50763 6.51124 8.38837 6.1213L7.90076 4.5269Z'
                fill='currentColor'
            />
            <path
                d='M18.1653 4.5269C18.108 4.33965 17.9765 4.18214 17.7996 4.08904C17.6227 3.99594 17.4149 3.97487 17.222 4.03046C15.3505 4.56979 13.4706 6.25196 12.5049 8.3922C12.0125 9.48329 11.6119 10.9656 11.5965 12.3969C11.5814 13.8095 11.9451 15.3423 13.1902 16.2937C13.5857 16.5959 13.999 16.8422 14.5471 16.9461C15.072 17.0455 15.6477 17 16.347 16.8705C17.9508 16.5734 19.3315 15.348 19.3315 13.6437V12.938C19.3315 11.2617 17.9317 9.90284 16.2048 9.90284H15.9511C15.7406 9.90284 15.535 9.92308 15.3361 9.96164C15.3754 9.85622 15.4182 9.75249 15.4642 9.65054C16.1128 8.21323 17.3255 7.27212 18.1415 7.03696C18.5432 6.9212 18.7722 6.51124 18.6529 6.1213L18.1653 4.5269Z'
                fill='currentColor'
            />
        </svg>
    );
}
