import { httpGet } from './apiService';
import { CarouselResult } from '@models/section/cardCarouselSection';

const tagBaseApiUrl = '/api/tag';

const taggedEntityTypeMap: { [key: string]: string | null } = {
    'tag-press-release': 'press-release',
    'tag-success-story': 'case-study',
    'tag-white-paper': 'white-paper',
    'tag-blog': 'blog',
    'tag-glossary': 'glossary',
    'tag-video': 'video',
};

export async function getCarouselByTag(tagId: number, taggedEntityType?: string): Promise<CarouselResult> {
    const entityType = taggedEntityType ? taggedEntityTypeMap[taggedEntityType] : null;

    const url = entityType ? `${tagBaseApiUrl}/carousel/${tagId}/${entityType}` : `${tagBaseApiUrl}/carousel/${tagId}`;

    return (await httpGet(url)).json();
}
