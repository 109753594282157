import React from 'react';
import { Stack } from '@mui/material';
import { featureIconsStyles as classes } from '../styleObjects/FeatureIcons';
import { ForwardIcon, Heading, V2Button } from '@web-for-marketing/react-ui';
import { Picture } from '@components/Picture';
import { BoxFeature } from '@models/section/featuresSection';
import { TextAlignValues } from '@models/editorForm';
import { RichTextContent } from '@components/RichTextContent';
import { getLinkTargetValue } from './SectionComponentHelper';

interface BoxFeatureItemProps {
    lazyLoad: boolean;
    alignFeatures: TextAlignValues;
    feature: BoxFeature;
    shouldIncreaseHeadingLevel: boolean;
}

export function BoxFeatureItem({
    feature,
    alignFeatures,
    lazyLoad,
    shouldIncreaseHeadingLevel,
}: BoxFeatureItemProps): JSX.Element {
    const { image, title, body, linkPath, linkText, ariaLabel, shouldOpenNewTab } = feature;

    return (
        <Stack direction='row' spacing={{ xs: '1.2rem', md: '1.6rem' }} css={classes.boxContainer}>
            <div>
                <Picture
                    imagePath={image.imagePath}
                    mobilePath={image.mobilePath ? image.mobilePath : ''}
                    alt={image.imageAlt}
                    mobileAlt={image.mobileAlt}
                    lazyLoad={lazyLoad}
                    css={classes.boxIcon}
                />
            </div>
            <Stack spacing={{ xs: '0.4rem', md: '0.8rem' }}>
                <Heading
                    variant='h5'
                    color='activePrimary'
                    component={shouldIncreaseHeadingLevel ? 'h2' : 'h3'}
                    css={classes[`${alignFeatures}Heading`]}
                >
                    {title}
                </Heading>
                <RichTextContent
                    content={body}
                    variant='body2'
                    color='secondary'
                    css={classes[`${alignFeatures}Body`]}
                />
                {linkText && linkPath ? (
                    <V2Button
                        variant='link'
                        component='a'
                        href={linkPath}
                        target={getLinkTargetValue(shouldOpenNewTab)}
                        aria-label={ariaLabel}
                        rightIcon={<ForwardIcon />}
                        css={classes[`${alignFeatures}Link`]}
                    >
                        {linkText}
                    </V2Button>
                ) : null}
            </Stack>
        </Stack>
    );
}
