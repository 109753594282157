import React from 'react';

export function TabIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            width='33'
            height='34'
            viewBox='0 0 33 34'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M7.54286 9.66526C7.54286 8.88417 8.17605 8.25098 8.95714 8.25098H31.5857C32.3668 8.25098 33 8.88417 33 9.66526C33 10.4464 32.3668 11.0795 31.5857 11.0795H8.95714C8.17605 11.0795 7.54286 10.4464 7.54286 9.66526ZM3.77143 17.2081C3.77143 16.427 4.40463 15.7938 5.18571 15.7938H27.8143C28.5954 15.7938 29.2286 16.427 29.2286 17.2081C29.2286 17.9892 28.5954 18.6224 27.8143 18.6224H5.18571C4.40463 18.6224 3.77143 17.9892 3.77143 17.2081ZM0 24.751C0 23.9699 0.633197 23.3367 1.41429 23.3367H24.0429C24.8239 23.3367 25.4571 23.9699 25.4571 24.751C25.4571 25.5321 24.8239 26.1653 24.0429 26.1653H1.41429C0.633197 26.1653 0 25.5321 0 24.751Z'
                fill='currentcolor'
            />
        </svg>
    );
}
