import React from 'react';
import { ClassNames } from '@emotion/react';
import { LazyLoadContainer } from '@web-for-marketing/react-ui';
import { HorizontalDock, ImageBackground, VerticalDock, ImageBackgroundConfig } from '@models/layeredBackground';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { PageHelmetPreloadLink } from '@components/PageHelmetPreloadLink';

interface BackgroundStyles {
    backgroundImage: string;
    backgroundSize: string;
    backgroundPosition: string;
    backgroundAttachment: string;
}

interface LayeredImageBackgroundProps {
    background: ImageBackground;
    lazyLoad: boolean;
}

function getDockedPosition(dock: HorizontalDock | VerticalDock): string {
    if (dock === HorizontalDock.Right || dock === VerticalDock.Bottom) {
        return '100%';
    }

    if (dock === HorizontalDock.Left || dock === VerticalDock.Top) {
        return '0%';
    }

    return '50%';
}

function getBackground(imageConfig: ImageBackgroundConfig, mediaUrl: string): BackgroundStyles {
    let backgroundSize = '';
    let backgroundAttachment = '';

    const backgroundImage = `url('${mediaUrl}')`;

    if (imageConfig.cover) {
        backgroundSize = 'cover';
    } else {
        backgroundSize = 'auto';
    }

    if (imageConfig.parallax && (imageConfig.verticalDock === VerticalDock.Center || imageConfig.cover)) {
        backgroundAttachment = 'fixed';
    } else {
        backgroundAttachment = 'local';
    }

    const horizontalPosition = `calc(${getDockedPosition(imageConfig.horizontalDock)} - ${
        imageConfig.horizontalOffset
    }px)`;
    const verticalPosition = `calc(${getDockedPosition(imageConfig.verticalDock)} - ${imageConfig.verticalOffset}px)`;

    const backgroundPosition = `${horizontalPosition} ${verticalPosition}`;

    return {
        backgroundImage,
        backgroundSize,
        backgroundPosition,
        backgroundAttachment,
    };
}

export function LayeredImageBackground({ background, lazyLoad }: LayeredImageBackgroundProps): JSX.Element {
    const { prefixStringWithBaseUrl } = useStaticValues();
    const prefixedMediaUrl = background.config.mediaUrl ? prefixStringWithBaseUrl(background.config.mediaUrl) : '';
    const { backgroundImage, backgroundPosition, backgroundSize, backgroundAttachment } = getBackground(
        background.config,
        prefixedMediaUrl
    );

    return (
        <>
            {!lazyLoad ? <PageHelmetPreloadLink as='image' href={prefixedMediaUrl} /> : null}
            <ClassNames>
                {({ css }) => (
                    <LazyLoadContainer
                        data-testid='layered-image-background'
                        lazyload={lazyLoad}
                        css={{
                            position: 'absolute',
                            inset: 0,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: backgroundSize,
                            backgroundPosition: backgroundPosition,
                            backgroundAttachment: backgroundAttachment,
                        }}
                        lazyClass={css({ backgroundImage: backgroundImage })}
                    />
                )}
            </ClassNames>
        </>
    );
}
