export function setSessionStorageItem(name, value) {
    try {
        sessionStorage.setItem(name, value);
    } catch (error) {
        //
    }
}

export function getSessionStorageItem(name) {
    try {
        return sessionStorage.getItem(name);
    } catch (error) {
        return null;
    }
}

export function removeSessionStorageItem(name) {
    try {
        sessionStorage.removeItem(name);
    } catch (error) {
        //
    }
}

export function clearSessionStorageItems() {
    try {
        sessionStorage.clear();
    } catch (error) {
        //
    }
}
