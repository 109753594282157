import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { SubNavigationData } from '@models/section/subNavigationSection';
import { subNavigationStyles as classes } from '@components/styleObjects/SubNavigation';
import { CMSLink } from '@components/CMSLink';
import { getLinkTargetValue } from './helpers/SectionComponentHelper';
import { NewTabIcon } from '@web-for-marketing/react-ui';
import { PopoutButton } from '@components/PopoutCustomSection/PopoutButton';
import { useInView } from 'react-intersection-observer';
import { useAtom, useSetAtom } from 'jotai';
import { isSubNavigationFixedPositionAtom, isMobileAtom, navHeightAtom } from '@atoms/appSettings';
import { isEditingAtom } from '@atoms/admin';
import { RightArrowIcon } from '@components/IconComponents/RightArrowIcon';
import { v2Colors } from '@web-for-marketing/react-ui';
import { SubNavigationMobile } from './SubNavigationMobile';
import { debounce } from '@helpers/debounceHelper';

export interface SubNavigationProps {
    sectionData: SubNavigationData;
}

export function SubNavigation({ sectionData }: SubNavigationProps): JSX.Element {
    const [navHeight] = useAtom(navHeightAtom);
    const [isEditing] = useAtom(isEditingAtom);
    const [isMobile] = useAtom(isMobileAtom);
    const containerRef = useRef<HTMLDivElement>(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const [isIntersecting, setIsIntersecting] = useState(false);

    const [ref, inView] = useInView({
        threshold: 1,
        rootMargin: `-${(!isMobile ? navHeight : 0) + (isEditing ? 60 : 0)}px 0px 0px`,
        onChange: (_inView, entries) => {
            setIsIntersecting(window.innerHeight > entries.boundingClientRect.top);
            if (entries.boundingClientRect.top < (isEditing ? 330 : navHeight)) {
                setIsSubNavigationFixedPositionAtom(true);
            } else {
                setIsSubNavigationFixedPositionAtom(false);
            }
        },
    });
    const setIsSubNavigationFixedPositionAtom = useSetAtom(isSubNavigationFixedPositionAtom);

    useEffect(() => {
        const container = containerRef.current;

        if (!container) return;

        const resizeObserver = new ResizeObserver(() => {
            updateScrollArrows();
        });

        resizeObserver.observe(container);

        return () => {
            resizeObserver.unobserve(container);
        };
    }, []);

    const updateScrollArrows = (): void => {
        const container = containerRef.current;
        if (!container) return;

        setShowLeftArrow(container.scrollLeft > 0);
        setShowRightArrow(container.scrollLeft + container.clientWidth < container.scrollWidth);
    };

    const handleScroll = (direction: 'left' | 'right'): void => {
        const container = containerRef.current;
        const adjustment = direction === 'left' ? -100 : 100;
        if (container) {
            container.scrollTo({
                left: container.scrollLeft + adjustment, // Adjust scroll amount as needed
                behavior: 'smooth',
            });
            updateScrollArrows();
        }
    };

    const handleScrollDebounced = debounce(updateScrollArrows, 100);

    useEffect(() => {
        document.documentElement.style.scrollPaddingTop = inView && !isMobile ? `${navHeight - 2}px` : '0';
    }, [inView, isMobile, navHeight]);

    return (
        <>
            <div ref={ref} css={classes.stickyHidden} />
            <div
                css={classes.subNavigationContainer(
                    !inView && isIntersecting,
                    (!isMobile ? (isEditing ? navHeight : 100) : 0) + (isEditing ? 60 : 0),
                    isMobile
                )}
            >
                {isMobile ? (
                    <SubNavigationMobile sectionData={sectionData} />
                ) : (
                    <div css={classes.subNavigation}>
                        {showLeftArrow ? (
                            <div css={classes.arrowContainer}>
                                <Button
                                    onClick={() => handleScroll('left')}
                                    css={classes.arrowButton}
                                    aria-hidden
                                    data-testid='scroll-left-button'
                                    tabIndex={-1}
                                >
                                    <RightArrowIcon color={v2Colors.text.activePrimary} css={classes.flipArrow} />
                                </Button>
                            </div>
                        ) : null}
                        <Grid
                            container
                            width='auto'
                            flexWrap='nowrap'
                            ref={containerRef}
                            data-testid='link-container'
                            css={classes.linkItemContainer}
                            onScroll={handleScrollDebounced}
                        >
                            {sectionData.links.map((link, index) => (
                                <Grid item key={`${JSON.stringify(link)}-${index}`} display='flex'>
                                    <CMSLink
                                        href={link.linkPath}
                                        target={getLinkTargetValue(link.linkTarget)}
                                        css={classes.linkItem}
                                    >
                                        {link.linkText}
                                        {link.linkTarget ? (
                                            <NewTabIcon aria-hidden css={classes.smallLeftMarginIcon} />
                                        ) : null}
                                    </CMSLink>
                                </Grid>
                            ))}
                        </Grid>
                        {showRightArrow ? (
                            <Grid item css={classes.arrowContainer}>
                                <Button
                                    onClick={() => handleScroll('right')}
                                    css={classes.arrowButton}
                                    aria-hidden
                                    tabIndex={-1}
                                    data-testid='scroll-right-button'
                                >
                                    <RightArrowIcon color={v2Colors.text.activePrimary} />
                                </Button>
                            </Grid>
                        ) : null}
                        {sectionData.buttons.length ? (
                            <>
                                <span data-testid='divider' css={classes.buttonDivider} />
                                <>
                                    {sectionData.buttons.map(
                                        (
                                            {
                                                buttonText,
                                                buttonLinkPath,
                                                buttonType,
                                                buttonTarget,
                                                buttonClassName,
                                                ariaLabel,
                                                isPopout,
                                                popoutCustomSection,
                                                params = [],
                                            },
                                            index
                                        ) =>
                                            buttonText ? (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md='auto'
                                                    css={classes.buttonContainer}
                                                    key={`${index}${buttonText}${popoutCustomSection}`}
                                                >
                                                    {isPopout ? (
                                                        <PopoutButton
                                                            text={buttonText}
                                                            variant={buttonType || 'contained'}
                                                            customSectionKey={popoutCustomSection}
                                                            params={params}
                                                            aria-label={ariaLabel}
                                                            css={classes.buttonItem}
                                                        />
                                                    ) : (
                                                        <CMSLink
                                                            type='button'
                                                            variant={buttonType || 'contained'}
                                                            href={buttonLinkPath}
                                                            target={getLinkTargetValue(buttonTarget)}
                                                            aria-label={ariaLabel}
                                                            buttonClassName={buttonClassName}
                                                            css={classes.buttonItem}
                                                        >
                                                            {buttonText}
                                                        </CMSLink>
                                                    )}
                                                </Grid>
                                            ) : null
                                    )}
                                </>
                            </>
                        ) : null}
                    </div>
                )}
            </div>
        </>
    );
}
