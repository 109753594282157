import React from 'react';
import { GradientBackground, GradientBackgroundConfig, GradientType } from '@models/layeredBackground';
import { getRgbaColor } from '@web-for-marketing/react-ui';

export interface LayeredGradientBackgroundProps {
    background: GradientBackground;
}

export const gradientTypes: Record<GradientType, string> = {
    [GradientType.Linear]: 'linear-gradient',
    [GradientType.Radial]: 'radial-gradient',
};

function getGradientBackground(gradientConfig: GradientBackgroundConfig): string | undefined {
    const type = gradientTypes[gradientConfig.type];
    const angle = gradientConfig.type === GradientType.Radial ? 'circle' : `${gradientConfig.angle}deg`;
    const colors: string[] = [];

    gradientConfig.colors.forEach((color) => {
        const rgbaColor = getRgbaColor(color.color, color.transparency);
        colors.push(`${rgbaColor} ${color.position}%`);
    });

    return `${type}(${angle}, ${colors.join(',')})`;
}

export function LayeredGradientBackground({ background }: LayeredGradientBackgroundProps): JSX.Element {
    return (
        <div
            data-testid='layered-gradient-background'
            css={{
                position: 'absolute',
                inset: 0,
                background: getGradientBackground(background.config),
            }}
        />
    );
}
