import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { GridContainer } from '@components/GeotabGridComponents/GridContainer';
import { Text, Heading } from '@web-for-marketing/react-ui';
import { capterraTestimonialStyles as classes } from './styleObjects/CapterraTestimonialStyles';
import { CapterraTestimonialSectionData } from '@models/section/capterraTestimonialSection';
import { CarouselDotTabs } from '@components/BlogSection/BlogComponents/Home/CarouselDotTabs';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { RightChevronIcon } from '@icon/RightChevronIcon';
import { CapterraTestimonialCard } from '@components/SectionComponents/CapterraTestimonialCard';

export interface CapterraTestimonialProps {
    sectionData: CapterraTestimonialSectionData;
    headerSection: boolean;
}

export function CapterraTestimonial({ sectionData, headerSection }: CapterraTestimonialProps): JSX.Element {
    const [activeStep, setActiveStep] = useState(0);
    const testimonialListArray = [];
    if (sectionData.testimonials.length > 4) {
        for (let i = 0; i < sectionData.testimonials.length; i += 4) {
            const testimonialCards = sectionData.testimonials.slice(i, i + 4);
            testimonialListArray.push(testimonialCards);
        }
    }
    const maxSteps = testimonialListArray.length > 0 ? testimonialListArray.length : 1;

    if (activeStep > maxSteps - 1) {
        setActiveStep(activeStep - 1);
    }

    function handleStepChange(step: number): void {
        setActiveStep(step);
    }

    return (
        <GridContainer container alignItems='center' flexDirection='column'>
            {maxSteps === 1 ? null : (
                <>
                    <button
                        css={[classes.chevronIcon, classes.leftChevronIcon, activeStep === 0 ? classes.grayIcon : '']}
                        onClick={() => {
                            handleStepChange(activeStep - 1);
                        }}
                        disabled={activeStep === 0}
                        aria-label='go to previous slide'
                    >
                        <RightChevronIcon />
                    </button>
                    <button
                        css={[
                            classes.chevronIcon,
                            classes.rightChevronIcon,
                            activeStep === maxSteps - 1 ? classes.grayIcon : '',
                        ]}
                        onClick={() => {
                            handleStepChange(activeStep + 1);
                        }}
                        disabled={activeStep === maxSteps - 1}
                        aria-label='go to next slide'
                    >
                        <RightChevronIcon />
                    </button>
                </>
            )}
            <Grid item css={classes.headerContainer}>
                <Heading variant='h2' component={headerSection ? 'h1' : 'h2'} align='center' css={classes.title}>
                    {sectionData.title}
                </Heading>
                {sectionData.description ? (
                    <Text variant='body1' color='primary'>
                        {sectionData.description}
                    </Text>
                ) : null}
            </Grid>
            <div css={{ margin: '2rem 0' }}>
                <SwipeableViews
                    axis='x'
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                    css={classes.swipeViewContainer}
                    springConfig={{
                        duration: '0.6s',
                        easeFunction: 'ease',
                        delay: '0s',
                    }}
                >
                    {testimonialListArray.length === 0 ? (
                        <Grid
                            container
                            justifyContent='center'
                            spacing={4}
                            data-testid='CapterraTestimonialSection'
                            css={classes.testimonialSectionContainer}
                        >
                            {sectionData.testimonials.map((testimonial, index) => {
                                return (
                                    <Grid
                                        item
                                        container
                                        justifyContent='center'
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={3}
                                        key={`${JSON.stringify(testimonial)}-${index}`}
                                    >
                                        <CapterraTestimonialCard testimonial={testimonial} />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    ) : (
                        testimonialListArray.map((subArray, index) => {
                            const isHidden = activeStep !== index;

                            return (
                                <Grid
                                    container
                                    justifyContent='center'
                                    spacing={4}
                                    data-testid='CapterraTestimonialSection'
                                    css={classes.testimonialSectionContainer}
                                    key={`${JSON.stringify(subArray)}-${index}`}
                                >
                                    {subArray.map((testimonial, index) => {
                                        return (
                                            <Grid
                                                item
                                                container
                                                justifyContent='center'
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={3}
                                                key={`${JSON.stringify(testimonial)}-${index}`}
                                            >
                                                <CapterraTestimonialCard
                                                    testimonial={testimonial}
                                                    isHidden={isHidden}
                                                />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            );
                        })
                    )}
                </SwipeableViews>
            </div>
            <div>
                <CarouselDotTabs maxDots={maxSteps} activeDot={activeStep} onChangeSlide={handleStepChange} />
            </div>
        </GridContainer>
    );
}
