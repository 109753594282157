import { httpGet } from './apiService';
import { PagedResult } from '@models/pagination';
import { TaggedSlugEntityPreview } from '@models/tag';

const baseApiUrl = '/api/press-release';

export async function getPagedPressReleaseList(
    pageNumber: number,
    rowsPerPage: number,
    languageId: number
): Promise<PagedResult<TaggedSlugEntityPreview>> {
    const url = `${baseApiUrl}/paged/${pageNumber}/${rowsPerPage}/${languageId}`;
    return (await httpGet(url)).json();
}
