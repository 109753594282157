export const iframeStyles = {
    container: {
        width: '100%',
    },
    iframe: {
        display: 'block',
        margin: '20px auto',
    },
    invalidHeading: {
        textAlign: 'center',
        margin: '0 auto',
    },
    removeBorder: {
        border: 'none',
    },
} as const;
