import { SectionBase } from '@models/section';
import { TaggedSlugEntityPreview } from '@models/tag';

export type CardCarouselSection = SectionBase<22, { cardCarousel: CardCarouselSectionData }>;

export enum CarouselType {
    Tag = 'tag',
    Category = 'category',
    Article = 'article',
}

export interface CardCarouselSectionData {
    title: string;
    body: string;
    carouselType?: CarouselType;
    carouselTypeEntityId?: number;
    articleType?: string;
    viewAllUrl?: string;
    hideCardLabel?: boolean;
}

export interface AdminCarouselItem {
    content: string;
    value: number;
}

export interface CarouselResult {
    entities: TaggedSlugEntityPreview[];
    carouselTypeEntitySlug?: string;
}
