import React from 'react';

export function VideoPlayIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            width='74'
            height='75'
            viewBox='0 0 74 75'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                d='M36.8634 0.386719C29.6444 0.386719 22.5875 2.55673 16.5851 6.62234C10.5827 10.688 5.90442 16.4666 3.14182 23.2274C0.379218 29.9883 -0.343604 37.4278 1.06476 44.6051C2.47312 51.7824 5.94941 58.3751 11.054 63.5497C16.1586 68.7242 22.6623 72.2481 29.7426 73.6758C36.8229 75.1034 44.1619 74.3707 50.8314 71.5703C57.5009 68.7698 63.2014 64.0274 67.2121 57.9428C71.2227 51.8582 73.3634 44.7046 73.3634 37.3867C73.353 27.577 69.5041 18.172 62.6613 11.2355C55.8184 4.29894 46.5406 0.397329 36.8634 0.386719V0.386719ZM51.5486 40.1432L29.098 51.5207C28.6344 51.7556 28.1193 51.8664 27.6016 51.8428C27.0838 51.8191 26.5806 51.6618 26.1397 51.3857C25.6988 51.1095 25.3348 50.7237 25.0823 50.265C24.8297 49.8062 24.697 49.2896 24.6968 48.7642V26.0031C24.6968 25.4778 24.8291 24.9613 25.0813 24.5025C25.3335 24.0437 25.6971 23.6578 26.1376 23.3814C26.5782 23.105 27.0811 22.9472 27.5986 22.9231C28.1162 22.8989 28.6313 23.0092 29.095 23.2435L51.5516 34.6117C52.0601 34.8668 52.488 35.2609 52.7871 35.7495C53.0862 36.2382 53.2445 36.8018 53.2442 37.3769C53.2439 37.9519 53.0849 38.5154 52.7853 39.0037C52.4857 39.492 52.0573 39.8857 51.5486 40.1401V40.1432Z'
                fill='white'
            />
        </svg>
    );
}
