import React, { useState, useEffect, useRef, useCallback } from 'react';
import { videoLibraryStyles as classes } from './videoLibraryStyles';
import { Grid, Skeleton } from '@mui/material';
import { TabIcon } from '@icon/TabIcon';
import { TagFilter, VideoTag } from '@models/section/videoLibrarySection';
import { TagFilterBarMenu } from './TagFilterBarMenu';

export interface TagFilterBarProps {
    onChange: (value: TagFilter) => void;
    selectedTag: TagFilter;
    tags: VideoTag[];
    connect2024?: boolean;
}

export function TagFilterBar({ onChange, selectedTag, tags, connect2024 }: TagFilterBarProps): JSX.Element {
    const [showFilterBar, setShowFilterBar] = useState(false);
    const [visibleCount, setVisibleCount] = useState(tags.length);
    const visibleTags = tags.slice(0, visibleCount);
    const menuItemsCount = tags.length - visibleTags.length;
    const menuItems = menuItemsCount !== 0 ? tags.slice(-menuItemsCount) : [];
    const filterContainer = useRef<HTMLDivElement>(null);

    const computeTags = useCallback((buttonWidths: number[], container: HTMLDivElement | null): void => {
        if (container) {
            let visibileCount = 0;
            // buttonWidths[0] is the width of the "All videos" button
            let remainingSpace = container.clientWidth - buttonWidths[0] - 180; // removes space for "All Videos", "More" button, and extra margin
            const tagWidths = buttonWidths.slice(1);
            tagWidths.forEach((width) => {
                remainingSpace = remainingSpace - width - 20;
                if (remainingSpace > 0) {
                    visibileCount++;
                }
            });
            setVisibleCount(visibileCount);
        }
    }, []);

    useEffect(() => {
        const buttonWidths: number[] = [];

        if (filterContainer.current) {
            const shouldUpdateStyles = filterContainer.current.style.display === 'none';
            if (shouldUpdateStyles) {
                // buttons in container need to be visible to get their widths
                filterContainer.current.style.display = 'flex';
            }

            [...filterContainer.current.getElementsByTagName('button')].forEach((button) => {
                const { width } = button.getBoundingClientRect();
                buttonWidths.push(width);
            });
            computeTags(buttonWidths, filterContainer.current);

            if (shouldUpdateStyles) {
                // filter bar should stay hidden until visibleCount state has been updated
                filterContainer.current.style.display = 'none';
                setShowFilterBar(true);
            }
        }

        const handleResize = (): void => computeTags(buttonWidths, filterContainer.current);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [tags.length, computeTags]);

    function handleFilterChange(tagFilter: TagFilter): void {
        if (tagFilter !== selectedTag) {
            onChange(tagFilter);
        }
    }

    return (
        <>
            <Grid
                container
                justifyContent='center'
                wrap='nowrap'
                css={classes.filterContainer}
                ref={filterContainer}
                style={{ display: !showFilterBar ? 'none' : 'flex' }}
            >
                <button
                    css={classes.filterChip(selectedTag === 'All', connect2024)}
                    onClick={() => handleFilterChange('All')}
                >
                    <TabIcon css={{ marginRight: '1rem' }} />
                    All Videos
                </button>
                {visibleTags.map((tag) => (
                    <button
                        key={tag.id}
                        css={classes.filterChip(selectedTag === tag.id, connect2024)}
                        onClick={() => handleFilterChange(tag.id)}
                    >
                        {tag.name}
                    </button>
                ))}
                {menuItems.length ? (
                    <TagFilterBarMenu
                        connect2024={connect2024}
                        onSelect={handleFilterChange}
                        menuItems={menuItems}
                        selectedTag={selectedTag}
                    />
                ) : null}
            </Grid>
            {!showFilterBar ? (
                <Grid
                    data-testid='tagFilterBarSkeletons'
                    container
                    justifyContent='center'
                    wrap='nowrap'
                    css={[classes.topSpacing, classes.filterContainer]}
                >
                    {Array.from(new Array(5)).map((_item, index) => (
                        <Skeleton
                            key={index}
                            variant='rounded'
                            animation='wave'
                            height={65}
                            width='15%'
                            css={{ borderRadius: '100px' }}
                        />
                    ))}
                </Grid>
            ) : null}
        </>
    );
}
