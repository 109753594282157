import React from 'react';

export function RightChevronIconRound(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            width='44'
            height='44'
            viewBox='0 0 38 38'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                d='M15.5098 9.56603C15.1657 9.91007 15.1657 10.468 15.5098 10.8121L23.6978 18.9998L15.5098 27.188C15.1657 27.5321 15.1657 28.09 15.5098 28.4341C15.8539 28.7782 16.4118 28.7782 16.7559 28.4341L25.5669 19.6228C25.7321 19.4576 25.8249 19.2335 25.8249 18.9998C25.8249 18.7662 25.732 18.542 25.5668 18.3768L16.7558 9.56609C16.4118 9.22193 15.8539 9.22193 15.5098 9.56603Z'
                fill='white'
            />
            <rect x='1' y='1' width='36' height='36' rx='18' stroke='white' strokeWidth='2' />
        </svg>
    );
}
