import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { GridContainer } from '@components/GeotabGridComponents/GridContainer';
import { LazyLoadContainer, Heading, Text } from '@web-for-marketing/react-ui';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { ppcFormHeaderStyles as classes } from './styleObjects/PPCFormHeaderStyles';
import { DownChevronIcon } from '@icon/DownChevronIcon';
import { CustomFormPPCHeaderSectionData } from '@models/section/customFormPPCHeaderSection';
import { ClassNames } from '@emotion/react';
import { PageHelmetPreloadLink } from '@components/PageHelmetPreloadLink';
import loadable from '@loadable/component';
import { RichTextContent } from '@components/RichTextContent';

interface CustomFormPPCHeaderProps {
    sectionData: CustomFormPPCHeaderSectionData;
    lazyLoad: boolean;
    headerSection?: boolean;
}

const CustomFormLoadable = loadable(() => import('@components/SectionComponents/CustomForm'));

export function CustomFormPPCHeader({ sectionData, lazyLoad, headerSection }: CustomFormPPCHeaderProps): JSX.Element {
    const { heading, description, bannerImage, testimonial, goToSection, customForm } = sectionData;
    const { prefixStringWithBaseUrl } = useStaticValues();

    const imageUrl = useMemo(
        () => (bannerImage ? prefixStringWithBaseUrl(bannerImage) : ''),
        [bannerImage, prefixStringWithBaseUrl]
    );

    const authorImageUrl = useMemo(
        () => (testimonial.authorImage ? prefixStringWithBaseUrl(testimonial.authorImage) : ''),
        [testimonial.authorImage, prefixStringWithBaseUrl]
    );

    return (
        <ClassNames>
            {({ css }) => (
                <LazyLoadContainer
                    component={Grid}
                    lazyload={lazyLoad}
                    lazyClass={css(classes.backgroundWithImage(imageUrl))}
                    css={classes.defaultBackground}
                    data-testid='ppcHeaderBackground'
                >
                    {!lazyLoad && imageUrl ? <PageHelmetPreloadLink as='image' href={imageUrl} /> : null}
                    <GridContainer container css={classes.sectionContainer} justifyContent='center' alignItems='center'>
                        <Grid item sm={12} md={7} css={classes.leftContent}>
                            <div css={classes.headingContainer}>
                                {heading ? (
                                    <Heading
                                        variant='h1'
                                        component={headerSection ? 'h1' : 'h2'}
                                        color='inversePrimary'
                                        css={classes.headingContent}
                                    >
                                        {heading}
                                    </Heading>
                                ) : null}
                                {description ? (
                                    <RichTextContent
                                        content={description}
                                        variant='h3'
                                        weight='regular'
                                        color='inversePrimary'
                                        css={classes.headingContent}
                                    />
                                ) : null}
                                {testimonial.hideTestimonial ? null : (
                                    <LazyLoadContainer
                                        component={Grid}
                                        lazyload={lazyLoad}
                                        container
                                        alignItems='center'
                                        wrap='nowrap'
                                        css={[classes.testimonial, classes.headingContent]}
                                        data-testid='ppcFormHeaderTestimonial'
                                    >
                                        {authorImageUrl ? (
                                            <img
                                                css={classes.testimonialImageDesktop}
                                                src={lazyLoad ? '' : authorImageUrl}
                                                data-src={lazyLoad ? authorImageUrl : undefined}
                                                data-testid='authorDesktopImage'
                                                alt={testimonial.author}
                                            />
                                        ) : null}
                                        <div>
                                            <Heading variant='h4' component='q' color='inversePrimary'>
                                                {testimonial.quote}
                                            </Heading>
                                            <Grid
                                                container
                                                alignItems='center'
                                                wrap='nowrap'
                                                css={classes.authorContainer}
                                            >
                                                {authorImageUrl ? (
                                                    <img
                                                        css={classes.testimonialImageMobile}
                                                        src={lazyLoad ? '' : authorImageUrl}
                                                        data-src={lazyLoad ? authorImageUrl : undefined}
                                                        data-testid='authorMobileImage'
                                                        alt={testimonial.author}
                                                    />
                                                ) : null}
                                                <Text variant='body2' color='inversePrimary'>
                                                    {testimonial.author}
                                                </Text>
                                            </Grid>
                                        </div>
                                    </LazyLoadContainer>
                                )}
                            </div>
                        </Grid>
                        <Grid
                            item
                            sm={12}
                            md={5}
                            css={classes.customFormContainerDesktop}
                            data-testid='customFormContainerDesktop'
                        >
                            <div css={classes.formContainer}>
                                <CustomFormLoadable sectionData={customForm} lazyLoad={lazyLoad} embed />
                            </div>
                        </Grid>
                        {goToSection ? (
                            <a
                                css={[classes.nextSectionLink, classes.nextSectionLinkDesktop]}
                                href={`#${goToSection}`}
                                aria-label='Go to next section'
                                data-testid='nextSectionLinkDesktop'
                            >
                                <DownChevronIcon />
                            </a>
                        ) : null}
                    </GridContainer>
                    <div
                        css={[classes.customFormContainerMobile, classes.formContainer]}
                        data-testid='customFormContainerMobile'
                    >
                        <CustomFormLoadable sectionData={customForm} lazyLoad={lazyLoad} embed />
                    </div>
                </LazyLoadContainer>
            )}
        </ClassNames>
    );
}
