import React from 'react';
import { Background, BackgroundType } from '@models/layeredBackground';
import { LayeredImageBackground } from './LayeredImageBackground';
import { LayeredVideoBackground } from './LayeredVideoBackground';
import { LayeredColorBackground } from './LayeredColorBackground';
import { LayeredGradientBackground } from './LayeredGradientBackground';

interface LayeredBackgroundProps {
    backgroundLayers: Background[];
    lazyLoad: boolean;
}

export function LayeredBackground({ backgroundLayers, lazyLoad }: LayeredBackgroundProps): JSX.Element {
    return (
        <>
            {backgroundLayers.map((background, index) => {
                if (background.enabled) {
                    const key = `${JSON.stringify(background)}-${index}`;
                    switch (background.type) {
                        case BackgroundType.Image:
                            return <LayeredImageBackground key={key} background={background} lazyLoad={lazyLoad} />;
                        case BackgroundType.Video:
                            return <LayeredVideoBackground key={key} background={background} lazyLoad={lazyLoad} />;
                        case BackgroundType.Color:
                            return <LayeredColorBackground key={key} background={background} />;
                        case BackgroundType.Gradient:
                            return <LayeredGradientBackground key={key} background={background} />;
                        default:
                            return null;
                    }
                }
            })}
        </>
    );
}
