import { colors, breakpoints, v2Colors } from '@web-for-marketing/react-ui';

export const capterraTestimonialStyles = {
    testimonialSectionContainer: {
        borderRadius: '1rem',
        padding: '0 1rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            padding: 0,
            borderRadius: '0.8rem 0.8rem 0 0',
        },
    },
    swipeViewContainer: {
        width: '100%',
    },
    headerContainer: {
        maxWidth: '72rem',
        margin: '0 auto',
        textAlign: 'center',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginBottom: '2.4rem',
        },
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            display: 'none',
        },
    },
    title: {
        margin: '0 auto 1.5rem',
    },
    testimonialGridContainer: {
        overflow: 'hidden',
        position: 'relative',
    },
    quoteAuthorSectionMargin: {
        marginTop: '2.5rem',
    },
    horizontalDivider: {
        display: 'block',
        border: `solid 1.5px ${colors.interactive.primary}`,
        color: colors.interactive.primary,
        width: '3.2rem',
        margin: '1.2rem 0',
    },
    chevronIcon: {
        overflow: 'visible',
        position: 'absolute',
        top: '45%',
        zIndex: '999',
        cursor: 'pointer',
        background: 'none',
        border: 'none',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            display: 'none',
        },
    },
    rightChevronIcon: {
        right: 0,
    },
    leftChevronIcon: {
        left: 0,
        transform: 'rotate(180deg)',
    },
    grayIcon: {
        '& path': {
            fill: v2Colors.core.smoke,
        },
    },
    cardBody: {
        minHeight: '43rem',
        minWidth: '22rem',
        border: `solid 1px ${v2Colors.border.primary}`,
        borderRadius: '0.8rem',
        backgroundColor: v2Colors.background.white,
        padding: '3.2rem',
        textAlign: 'left',
        '&:hover': {
            boxShadow: '0 0.4rem 1.2rem 0 rgba(0, 0, 0, 0.1)',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            maxWidth: '50rem',
        },
        [`@media (max-width: ${breakpoints.sm}px)`]: {
            padding: '2.4rem',
            height: 'auto',
        },
    },
    testimonialQuote: {
        width: '100%',
        marginTop: '1.2rem',
        maxHeight: '17rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '5',
        flex: '1',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            fontSize: '1.8rem',
        },
    },
    cardLogo: {
        width: '12rem',
    },
    newTabIcon: {
        color: colors.interactive.inverseSecondary,
        height: '1.8rem',
        width: '1.8rem',
    },
    authorContainer: {
        width: '100%',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            fontSize: '1.4rem',
        },
    },
    legalText: {
        marginTop: '1.2rem',
        fontSize: '1rem !important',
    },
} as const;
