import React, { useState, useRef } from 'react';
import { TagFilter, VideoTag } from '@models/section/videoLibrarySection';
import { useAccessiblePopout } from '@web-for-marketing/react-ui';
import { shadows, v2Colors } from '@web-for-marketing/react-ui';
import { typographyStyles } from '@web-for-marketing/react-ui';
import { ExpandIcon } from '@web-for-marketing/react-ui';
import { connectColors } from '@components/Connect2024/connectHelpers';

export interface MobileTagFilterBarProps {
    onChange: (value: TagFilter) => void;
    selectedTag: TagFilter;
    tags: VideoTag[];
    connect2024?: boolean;
}

const classes = {
    mobileTagFilter: {
        width: '100vw',
        margin: '0 -3.6rem',
    },
    button: (connect?: boolean) =>
        ({
            border: 'none',
            background: 'none',
            ...typographyStyles.header4,
            color: connect ? connectColors.white : typographyStyles.header4.color,
            fontWeight: 400,
            display: 'block',
            width: '100%',
            padding: '1.6rem 3.6rem',
            textAlign: 'left',
        }) as const,
    drodownButton: (connect?: boolean) => ({
        borderTop: `1px solid ${connect ? connectColors.white : v2Colors.border.primary}`,
        borderBottom: `1px solid ${connect ? connectColors.white : v2Colors.border.primary}`,
        display: 'flex',
        justifyContent: 'space-between',
    }),
    dropdown: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxHeight: 0,
        overflow: 'hidden',
        transition: 'max-height 500ms ease',
    },
    showOpen: (connect?: boolean) => ({
        boxShadow: shadows.soft,
        borderBottom: connect ? `1px solid ${connectColors.white}` : undefined,
        maxHeight: '200rem',
        transition: 'max-height 500ms ease',
    }),
    expandIcon: (connect?: boolean) => ({
        transition: 'transform 500ms ease',
        '& path': {
            fill: connect ? connectColors.white : undefined,
        },
    }),
    rotate: {
        transform: 'rotate(180deg)',
    },
    selected: (connect?: boolean) => ({
        backgroundColor: connect ? connectColors.white : v2Colors.core.cloud,
        color: connect ? connectColors.electricBlue : undefined,
    }),
} as const;

export function MobileTagFilterBar({ onChange, selectedTag, tags, connect2024 }: MobileTagFilterBarProps): JSX.Element {
    const [open, setOpen] = useState(false);
    const popover = useRef<HTMLDivElement>(null);
    useAccessiblePopout(popover, open, close, {
        disableScroll: false,
    });
    const buttonText = selectedTag === 'All' ? 'All Videos' : tags.find((tag) => tag.id === selectedTag)?.name;

    function handleFilterChange(tagFilter: TagFilter): void {
        if (tagFilter !== selectedTag) {
            onChange(tagFilter);
        }
    }

    function close(): void {
        setOpen(false);
    }

    function toggleOpen(): void {
        setOpen((open) => !open);
    }

    function handleSelection(selection: TagFilter): void {
        handleFilterChange(selection);
        close();
    }

    return (
        <div css={classes.mobileTagFilter} ref={popover}>
            <button
                data-testid='mobileTagFilterSelectedButton'
                onClick={toggleOpen}
                css={[classes.button(connect2024), classes.drodownButton(connect2024)]}
            >
                {buttonText}
                <ExpandIcon css={[classes.expandIcon(connect2024), open ? classes.rotate : undefined]} />
            </button>
            <div css={[classes.dropdown, open ? classes.showOpen(connect2024) : undefined]}>
                <button
                    onClick={() => handleSelection('All')}
                    css={[
                        classes.button(connect2024),
                        selectedTag === 'All' ? classes.selected(connect2024) : undefined,
                    ]}
                    tabIndex={open ? 0 : -1}
                >
                    All Videos
                </button>
                {tags.map((tag) => (
                    <button
                        key={tag.id}
                        onClick={() => handleSelection(tag.id)}
                        css={[
                            classes.button(connect2024),
                            selectedTag === tag.id ? classes.selected(connect2024) : undefined,
                        ]}
                        tabIndex={open ? 0 : -1}
                    >
                        {tag.name}
                    </button>
                ))}
            </div>
        </div>
    );
}
