import { shadows, breakpoints, v2Colors } from '@web-for-marketing/react-ui';
import { ColumnCount, TestimonialBackgroundColor } from '@models/column';

const testimonialBackgrounds = {
    [TestimonialBackgroundColor.Gray]: v2Colors.background.light,
    [TestimonialBackgroundColor.White]: v2Colors.background.white,
};

export const testimonialStyles = {
    testimonialBackground: (color?: TestimonialBackgroundColor) => ({
        backgroundColor: testimonialBackgrounds[color || TestimonialBackgroundColor.Gray],
        boxShadow: color === TestimonialBackgroundColor.White ? shadows.hard : undefined,
        padding: '2.4rem',
        borderRadius: '8px',
    }),
    ratingContainer: {
        marginBottom: '1.6rem',
    },
    linkContainer: {
        marginTop: '2.4rem',
    },
    testimonialLink: {
        color: v2Colors.text.activePrimary,
        fontWeight: 500,
        borderRadius: '8px',
        padding: '0.8rem 1.6rem',
        marginLeft: '-1.6rem',
        '&:hover': {
            backgroundColor: v2Colors.action.active,
            textDecoration: 'none',
        },
        '&:focus': {
            backgroundColor: v2Colors.action.active,
            color: v2Colors.text.activeSecondary,
            outline: 'none',
        },
    },
    linkText: {
        textDecoration: 'underline',
    },
    portrait: {
        width: '8rem',
        height: '8rem',
        borderRadius: '50%',
        marginBottom: '2rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginBottom: 0,
        },
    },
    profileContainer: (direction?: 'Left' | 'Right', columnCount?: ColumnCount) => ({
        maxWidth: columnCount === 3 ? '100%' : '30rem',
        width: columnCount === 3 ? '100%' : undefined,
        marginRight: direction !== 'Right' && columnCount !== 3 ? '2.4rem' : undefined,
        marginLeft: direction === 'Right' && columnCount !== 3 ? '2.4rem' : undefined,
        marginBottom: columnCount === 3 ? '1.6rem' : undefined,
        [`@media (max-width: ${breakpoints.lg}px)`]: {
            width: columnCount === 2 ? '100%' : undefined,
            maxWidth: columnCount === 2 ? '100%' : undefined,
            marginBottom: columnCount === 2 ? '1.6rem' : undefined,
            marginRight: columnCount === 2 ? 0 : undefined,
            marginLeft: columnCount === 2 ? 0 : undefined,
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            maxWidth: '100% !important',
            marginBottom: '1.6rem',
            marginLeft: '0 !important',
            marginRight: '0 !important',
        },
    }),
    content: {
        flex: 1,
    },
    profileInfo: (columnCount?: ColumnCount) => ({
        maxWidth: columnCount === 3 ? '30rem' : '100%',
        [`@media (max-width: ${breakpoints.lg}px)`]: {
            maxWidth: columnCount === 2 ? '30rem' : undefined,
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            maxWidth: '30rem !important',
            marginLeft: '2rem',
        },
    }),
    quoteIcon: {
        color: v2Colors.core.haze,
        width: '2.4rem',
        height: '2.4rem',
    },
    newTabIcon: {
        verticalAlign: 'text-top',
        width: '1em',
        height: '1em',
        '& path': {
            fill: 'currentColor',
        },
    },
} as const;
