import { Module } from '@models/section/modularSection';
import { ColumnCount } from '@models/column';

export function getTotalColumnSpanCount(modules: Module[]): number {
    let count = 0;
    modules.forEach((module) => {
        count += module.columnSpan;
    });

    return count;
}

export function getColumnCount(colSpan: number): ColumnCount {
    if (colSpan < 6) {
        return 3;
    }

    if (colSpan < 12) {
        return 2;
    }

    return 1;
}
