import { v2Colors } from '@web-for-marketing/react-ui';

export const pictureDialogStyle = {
    fullWidth: {
        width: '100%',
    },
    image: {
        objectFit: 'cover',
        withd: 'auto',
    },
    overlay: {
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        margin: 'auto',
        opacity: 0,
        transition: '.5s ease',
        backgroundColor: v2Colors.core.geotab,
        width: '100%',
        '&:hover, &:focus': {
            opacity: '0.7',
        },
    },
    overlayText: {
        color: '#FFFFFF',
        fontSize: '3rem',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    pictureDialog: {
        width: '90%',
        maxHeight: '90%',
        margin: '1rem',
        background: '#FFFFFF',
        borderRadius: '1rem',
        padding: '1rem',
        outline: 'none',
        overflow: 'auto',
        position: 'relative',
        '& img': {
            width: 'auto !important',
        },
    },
    closeButton: {
        top: '1.5rem',
        left: '1.5rem',
        position: 'absolute',
        backgroundColor: '#FFFFFF',
        boxShadow: '0.1rem 0.1rem 0.25rem',
        opacity: '0.7',
        '&:hover, &:focus': {
            backgroundColor: '#FFFFFF',
            opacity: '1',
        },
    },
} as const;
