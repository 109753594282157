import React, { useCallback } from 'react';
import { Divider } from '@mui/material';
import {
    StandardImageBanner,
    CallToActionBanner,
    ThreeColumnCards,
    FourColumnImages,
    CustomizableImageGallery,
    CustomSection,
    VideoCards,
    EventsSections,
    LinkList,
    BoxTiles,
    ResourceBoxes,
    ScrollingBanner,
    AccordionList,
    PPCFormHeader,
    Testimonial,
    FeatureAccordion,
    MultistepSection,
    AnimatedStatsSection,
    MarketplaceSolutions,
    VerticalAccordion,
    CapterraBanner,
} from './SectionComponents';
import { Features } from './SectionComponents/Features';
import { Iframe } from './SectionComponents/Iframe';
import { CardCarousel } from './SectionComponents/CardCarousel';
import { CustomFormPPCHeader } from './SectionComponents/CustomFormPPCHeader';
import { isMobileAtom } from '@atoms/appSettings';
import { useAtom } from 'jotai';
import { useLazyLoad } from '@hooks/useLazyLoad';
import { PageSectionWrapper } from './PageSectionWrapper';
import loadable from '@loadable/component';
import { CustomFormLeadshareBox } from './SectionComponents/CustomFormLeadshareBox';
import { CapterraTestimonial } from './SectionComponents/CapterraTestimonial';
import { VideoLibrary } from './SectionComponents/VideoLibrary/VideoLibrary';
import { GTMDataLayerForwarder } from './SectionComponents/GTMDataLayerForwarder';
import { Section } from '@models/section';
import { FeaturesTypeContent } from '@models/section/featuresSection';
import { CardsTypeContent } from '@models/section/threeColumnCardsSection';
import { FourColumnImagesTypeContent } from '@models/section/fourColumnImageSection';
import { ImageGalleryTypeContent } from '@models/section/customizableImageGallerySection';
import { ModularSection } from './SectionComponents/ModularSection';
import { SubNavigation } from './SectionComponents/SubNavigation';
import { DeviceComparison } from './SectionComponents/DeviceComparison/DeviceComparison';
import { LandingPageHeader } from './SectionComponents/LandingPageHeader';

const CustomFormLoadable = loadable(() => import('@components/SectionComponents/CustomForm'));
const LeadShareLoadable = loadable(() => import('@components/SectionComponents/LeadShare'));

interface PageSectionProps {
    section: Section;
}

function PageSectionComponent({ section }: PageSectionProps): JSX.Element | null {
    const [isMobile] = useAtom(isMobileAtom);
    const lazyLoad = useLazyLoad(section ? section.order > 2 : false);

    const getSelection = useCallback(
        (sectionData: Section) => {
            if (sectionData) {
                const { order, videos } = sectionData;

                switch (sectionData.sectionTypeId) {
                    case 1:
                        return (
                            <StandardImageBanner
                                headerBanner={order === 1}
                                imagePath={sectionData.jsonContent.imagePath}
                                mobileImagePath={
                                    (!!sectionData.jsonContent.mobileImagePath &&
                                        sectionData.jsonContent.mobileImagePath) ||
                                    ''
                                }
                                alt={sectionData.jsonContent.alt}
                                backgroundPosition={sectionData.jsonContent.backgroundPosition}
                                parallax={sectionData.jsonContent.parallax}
                                title={sectionData.jsonContent.title}
                                description={sectionData.jsonContent.description}
                                list={sectionData.jsonContent.list}
                                buttons={sectionData.jsonContent.buttons}
                                align={sectionData.jsonContent.align}
                                animations={sectionData.jsonContent.animations}
                                zIndex={order}
                                lazyLoad={lazyLoad}
                                imageType={sectionData.jsonContent.imageType}
                                mobileImageType={sectionData.jsonContent.mobileImageType}
                                displayContentBlock={sectionData.jsonContent.displayContentBlock}
                                contentBoxBackgroundColor={sectionData.jsonContent.contentBoxBackgroundColor}
                                mobileAlt={sectionData.jsonContent.mobileAlt}
                                mobileDecoration={sectionData.jsonContent.mobileDecoration}
                            />
                        );
                    case 3: {
                        const typeVariationProps = {
                            type: sectionData.jsonContent.type,
                            features: sectionData.jsonContent.features,
                        };
                        return (
                            <Features
                                headerSection={order === 1}
                                {...(typeVariationProps as FeaturesTypeContent)}
                                title={sectionData.jsonContent.title}
                                body={sectionData.jsonContent.body}
                                align={sectionData.jsonContent.align}
                                alignFeatures={sectionData.jsonContent.alignFeatures}
                                animations={sectionData.jsonContent.animations}
                                lazyLoad={lazyLoad}
                            />
                        );
                    }
                    case 4: {
                        const typeVariationProps = {
                            type: sectionData.jsonContent.type,
                            cards: sectionData.jsonContent.cards,
                        };
                        return (
                            <ThreeColumnCards
                                {...(typeVariationProps as CardsTypeContent)}
                                title={sectionData.jsonContent.title}
                                align={sectionData.jsonContent.align}
                                textAlign={sectionData.jsonContent.textAlign}
                                animations={sectionData.jsonContent.animations}
                                zIndex={order}
                                lazyLoad={lazyLoad}
                                headerSection={order === 1}
                            />
                        );
                    }
                    case 5:
                        return (
                            <CallToActionBanner
                                headerBanner={order === 1}
                                imagePath={sectionData.jsonContent.imagePath}
                                backgroundPosition={sectionData.jsonContent.backgroundPosition}
                                title={sectionData.jsonContent.title}
                                description={sectionData.jsonContent.description}
                                customProps={sectionData.jsonContent.customProps}
                                buttons={sectionData.jsonContent.buttons}
                                align={sectionData.jsonContent.align}
                                animations={sectionData.jsonContent.animations}
                                zIndex={order}
                                lazyLoad={lazyLoad}
                                settings={sectionData.jsonContent.settings}
                            />
                        );
                    case 7: {
                        const typeVariationProps = {
                            type: sectionData.jsonContent.type,
                            imageBoxes: sectionData.jsonContent.imageBoxes,
                        };
                        return (
                            <FourColumnImages
                                headerSection={order === 1}
                                {...(typeVariationProps as FourColumnImagesTypeContent)}
                                title={sectionData.jsonContent.title}
                                align={sectionData.jsonContent.align}
                                animations={sectionData.jsonContent.animations}
                                zIndex={order}
                                lazyLoad={lazyLoad}
                            />
                        );
                    }
                    case 8: {
                        const typeVariationProps = {
                            type: sectionData.jsonContent.type,
                            images: sectionData.jsonContent.images,
                        };
                        return (
                            <CustomizableImageGallery
                                headerSection={order === 1}
                                {...(typeVariationProps as ImageGalleryTypeContent)}
                                title={sectionData.jsonContent.title}
                                size={sectionData.jsonContent.size}
                                align={sectionData.jsonContent.align}
                                animations={sectionData.jsonContent.animations}
                                zIndex={order}
                                lazyLoad={lazyLoad}
                                background={sectionData.jsonContent.background}
                            />
                        );
                    }
                    case 9:
                        return (
                            <CustomSection
                                headerSection={order === 1}
                                component={sectionData.jsonContent.component}
                                params={sectionData.jsonContent.params}
                                lazyLoad={lazyLoad}
                            />
                        );
                    case 10:
                        return <VideoCards videos={videos || []} zIndex={order} lazyLoad={lazyLoad} />;
                    case 11:
                        return (
                            <EventsSections
                                headerSection={order === 1}
                                title={sectionData.jsonContent.title}
                                events={sectionData.jsonContent.events}
                                openBottom={sectionData.jsonContent.openBottom}
                                animations={sectionData.jsonContent.animations}
                                zIndex={order}
                                lazyLoad={lazyLoad}
                            />
                        );
                    case 12:
                        return (
                            <LinkList
                                headerSection={order === 1}
                                title={sectionData.jsonContent.title}
                                rows={sectionData.jsonContent.rows}
                                animations={sectionData.jsonContent.animations}
                                zIndex={order}
                                lazyLoad={lazyLoad}
                                spacing={sectionData.jsonContent.spacing}
                            />
                        );
                    case 13:
                        return <BoxTiles cards={sectionData.jsonContent.cards} lazyLoad={lazyLoad} />;
                    case 14:
                        return (
                            <ResourceBoxes
                                headerSection={order === 1}
                                cards={sectionData.jsonContent.cards}
                                title={sectionData.jsonContent.title}
                                params={sectionData.jsonContent.params}
                                animations={sectionData.jsonContent.animations}
                                zIndex={order}
                                lazyLoad={lazyLoad}
                            />
                        );
                    case 15:
                        return (
                            <ScrollingBanner
                                banners={sectionData.jsonContent.banners}
                                settings={sectionData.jsonContent.settings}
                                zIndex={order}
                                lazyLoad={lazyLoad}
                            />
                        );
                    case 16:
                        return (
                            <LeadShareLoadable
                                leadBoxComponent={sectionData.jsonContent.leadBoxComponent}
                                animations={sectionData.jsonContent.animations}
                                lazyLoad={lazyLoad}
                            />
                        );
                    case 17:
                        return (
                            <AccordionList
                                headerSection={order === 1}
                                list={sectionData.jsonContent.list}
                                settings={sectionData.jsonContent.settings}
                                zIndex={order}
                                title={sectionData.jsonContent.title}
                            />
                        );
                    case 18:
                        return (
                            <Iframe
                                iframeComponent={sectionData.jsonContent.iframeComponent}
                                animations={sectionData.jsonContent.animations}
                                lazyLoad={lazyLoad}
                            />
                        );
                    case 20:
                        return (
                            <PPCFormHeader
                                sectionData={sectionData.jsonContent.ppcFormHeader}
                                lazyLoad={lazyLoad}
                                headerSection={order === 1}
                            />
                        );
                    case 21:
                        return (
                            <CustomFormLoadable
                                sectionData={sectionData.jsonContent.customForm}
                                lazyLoad={lazyLoad}
                                headerSection={order === 1}
                            />
                        );
                    case 22:
                        return (
                            <CardCarousel
                                sectionData={sectionData.jsonContent.cardCarousel}
                                lazyLoad={lazyLoad}
                                key={sectionData.jsonContent.cardCarousel.carouselType}
                                headerSection={order === 1}
                            />
                        );
                    case 23:
                        return (
                            <CustomFormPPCHeader
                                headerSection={order === 1}
                                sectionData={sectionData.jsonContent.customFormPPCHeader}
                                lazyLoad={lazyLoad}
                            />
                        );
                    case 24:
                        return (
                            <Testimonial
                                sectionData={sectionData.jsonContent.testimonialComponent}
                                lazyLoad={lazyLoad}
                            />
                        );
                    case 25:
                        return (
                            <FeatureAccordion
                                sectionData={sectionData.jsonContent.featureAccordion}
                                lazyLoad={lazyLoad}
                            />
                        );
                    case 26:
                        return (
                            <MultistepSection
                                sectionData={sectionData.jsonContent.multiStepSection}
                                lazyLoad={lazyLoad}
                                headerSection={order === 1}
                            />
                        );
                    case 27:
                        return <AnimatedStatsSection sectionData={sectionData.jsonContent.animatedStatsSection} />;
                    case 28:
                        return (
                            <CustomFormLeadshareBox
                                sectionData={sectionData.jsonContent.customFormLeadshareBox}
                                lazyLoad={lazyLoad}
                                headerSection={order === 1}
                            />
                        );
                    case 29:
                        return (
                            <CapterraTestimonial
                                sectionData={sectionData.jsonContent.capterraTestimonial}
                                headerSection={order === 1}
                            />
                        );
                    case 30:
                        return <GTMDataLayerForwarder />;
                    case 31:
                        return (
                            <VideoLibrary
                                sectionData={sectionData.jsonContent.videoLibrary}
                                lazyLoad={lazyLoad}
                                headerSection={order === 1}
                            />
                        );
                    case 32:
                        return (
                            <MarketplaceSolutions
                                sectionData={sectionData.jsonContent.marketplaceSolutions}
                                sectionId={sectionData.id}
                                lazyLoad={lazyLoad}
                                headerSection={order === 1}
                            />
                        );
                    case 33:
                        return (
                            <ModularSection
                                headerSection={order === 1}
                                sectionData={sectionData.jsonContent.modularSection}
                                lazyLoad={lazyLoad}
                                animations={sectionData.jsonContent.animations}
                            />
                        );
                    case 34:
                        return <SubNavigation sectionData={sectionData.jsonContent.subNavigationSection} />;
                    case 35:
                        return (
                            <DeviceComparison
                                key={sectionData.jsonContent.deviceComparison.deviceType}
                                headerSection={order === 1}
                                sectionData={sectionData.jsonContent.deviceComparison}
                                lazyLoad={lazyLoad}
                            />
                        );
                    case 36:
                        return (
                            <VerticalAccordion
                                headerSection={order === 1}
                                sectionData={sectionData.jsonContent.verticalAccordion}
                                lazyLoad={lazyLoad}
                            />
                        );
                    case 37:
                        return (
                            <LandingPageHeader
                                headerSection={order === 1}
                                sectionData={sectionData.jsonContent.landingPageHeader}
                                lazyLoad={lazyLoad}
                            />
                        );
                    case 38:
                        return <CapterraBanner sectionData={sectionData.jsonContent.capterraBanner} />;
                    default:
                        return null;
                }
            }
        },
        [lazyLoad]
    );

    return section ? (
        <>
            {section.jsonContent.border === 'top' || section.jsonContent.border === 'both' ? <Divider light /> : null}
            {section.reference && section.reference !== '' ? (
                <div
                    id={section.reference}
                    style={{ position: 'absolute', marginTop: isMobile && section.order !== 1 ? 0 : '-11.5rem' }}
                />
            ) : null}
            <PageSectionWrapper section={section} lazyLoad={lazyLoad}>
                {getSelection(section)}
            </PageSectionWrapper>
            {section.jsonContent.border === 'bottom' || section.jsonContent.border === 'both' ? (
                <Divider light />
            ) : null}
        </>
    ) : null;
}

export const PageSection = React.memo(PageSectionComponent);
