import React from 'react';
import { Grid } from '@mui/material';
import { CapterraLogo } from '@icon/CapterraLogo';
import { RatingSelect } from '@components/BlogSection/BlogComponents/Shared/RatingSelect';
import { CMSLink } from '@components/CMSLink';
import { Divider } from '@mui/material';
import { capterraTestimonialStyles as classes } from './styleObjects/CapterraTestimonialStyles';
import { CapterraTestimonialContent } from '@models/section/capterraTestimonialSection';
import { Text } from '@web-for-marketing/react-ui';
import { NewTabIcon } from '@icon/NewTabIcon';

export interface TestimonialCardProps {
    testimonial: CapterraTestimonialContent;
    isHidden?: boolean;
}

export function CapterraTestimonialCard({ testimonial, isHidden }: TestimonialCardProps): JSX.Element {
    return (
        <Grid container direction='column' css={classes.cardBody} data-testid='CapterraTestimonialCard'>
            <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
                <Grid item>
                    <CapterraLogo css={classes.cardLogo} data-testid='CapterraLogo' />
                </Grid>
                <Grid item>
                    {testimonial.externalLink ? (
                        <CMSLink
                            underline='none'
                            href={testimonial.linkPath}
                            rel='noopener noreferrer'
                            type='link'
                            css={{ marginTop: '2rem' }}
                            target='_blank'
                            aria-label={`Read ${testimonial.authorName}'s review`}
                            tabIndex={isHidden ? -1 : 0}
                        >
                            <NewTabIcon css={classes.newTabIcon} />
                        </CMSLink>
                    ) : null}
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems='left'>
                <Grid item css={{ marginTop: '3px' }}>
                    <RatingSelect rating={testimonial.rating} starColor='#FFD600' starSize={16} />
                </Grid>
                <Grid item>
                    <Text variant='body2' color='tertiary'>{`${testimonial.rating}/5 stars`}</Text>
                </Grid>
            </Grid>
            <div css={classes.testimonialQuote}>
                <Text variant='body1' color='primary'>
                    {testimonial.quote}
                </Text>
            </div>
            <Divider css={classes.horizontalDivider} />
            <div css={classes.authorContainer}>
                <Text variant='body2' color='primary' weight='bold'>
                    {`${testimonial.authorName}, ${testimonial.authorRole}`}
                </Text>
                <Text variant='body2' color='primary'>
                    {testimonial.industry}
                </Text>
                <Text variant='body2' color='secondary'>
                    {testimonial.companySize}
                </Text>
                <Text color='tertiary' css={classes.legalText} aria-hidden='true'>
                    Capterra reviews constitute the subjective opinions of individual end-users based on their own
                    experiences and do not represent the views of Capterra or its affiliates
                </Text>
            </div>
        </Grid>
    );
}
